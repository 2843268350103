import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./basicsite.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import mmlogo from "../../assets/mm2.png";
import banner from "../../assets/bannersm.jpg";
import cover1 from "../../assets/comic/COVER6lq.jpg";
import cover2 from "../../assets/comic/EP1/page5.webp";
import cover3 from "../../assets/comic/EP1/page12.webp";
import cover4 from "../../assets/comic/EP1/page15.webp";
import Footer from "../general/Footer";
import { FaXTwitter } from "react-icons/fa6";
import { TbBrandDiscord } from "react-icons/tb";
import { BsInstagram } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";
import Strips from "../pages/Strips";
import { SiWebtoon } from "react-icons/si";
import { FaArrowCircleUp } from "react-icons/fa";
import { IoIosSwitch } from "react-icons/io";

const BasicSite = ({ setSiteType }) => {
  const coverImages = [cover1, cover2, cover3, cover4];
  const [selectedButton, setSelectedButton] = useState("Button3");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const handleButtonClick = (buttonName, path) => {
    setSelectedButton(buttonName);
    navigate(path);
  };
  const [skeletonHeight, setSkeletonHeight] = useState(
    window.innerHeight * 0.6
  );
  useEffect(() => {
    const updateHeight = () => setSkeletonHeight(window.innerHeight * 0.6);
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  const handleClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % coverImages.length);
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    const img = new Image();
    img.src = coverImages[currentIndex];
    img.onload = () => setIsLoading(false);
  }, [coverImages, currentIndex]);

  return (
    <div className="basic-site-container">
      {/*  NAVIGATION */}
      <div className="basic-site-nav">
        <div className="nav-basic-socials">
          <div className="socials">
            <a href="https://x.com/MaarsComics" target="blank">
              <FaXTwitter className="socialimg" />
            </a>

            <a href="https://discord.gg/DvXzSBX7bN" target="blank">
              <TbBrandDiscord className="socialimg" />
            </a>
            <a href="https://www.instagram.com/studio_maars/" target="blank">
              <BsInstagram className="socialimg" />
            </a>
            <a href="https://www.tiktok.com/@studio_maars" target="blank">
              <SiTiktok className="socialimg" />
            </a>
          </div>
        </div>
        <div className="nav-basic-logo">
          <Link to="/basic">
            <img src={mmlogo} alt="mmlogo" />
          </Link>
        </div>
        <div className="nav-basic-more">
          {" "}
          <button
            className="switch-button basic"
            onClick={() => {
              setSiteType("new");
              navigate("/");
            }}
          >
            <IoIosSwitch />
            <h6>SWITCH</h6>
          </button>
        </div>
      </div>
      {/* MAIN BANNER AND NEWS */}
      <div className="basic-info">
        <img src={banner} alt="banner" className="basic-banner" />
        {isLoading ? (
          <div className="basic-info-text">
            {" "}
            <Skeleton
              height={skeletonHeight}
              baseColor="#202020"
              highlightColor="#9df6ff33"
            />
          </div>
        ) : (
          <div className="basic-info-text">
            <div className="basic-info-description">
              <h4>"MENTAL MARVIN - THE FORK"</h4>
              <h6>Read the first issue of the Mental Marvin saga!</h6>
              <img
                src={coverImages[currentIndex]}
                alt="cover"
                onClick={handleClick}
              />
            </div>
            <div className="basic-info-links">
              <h6>READ ON FOLLOWING LINKS:</h6>
              <a
                href="https://www.webtoons.com/en/canvas/mental-marvin/list?title_no=996515&webtoon-platform-redirect=true"
                target="blank"
              >
                <SiWebtoon />
              </a>
            </div>
          </div>
        )}
      </div>
      <div className="basic-strips-title">
        <h2>SHORT COMIC STRIPS</h2>
        <h6>
          Enjoy the short Mental Marvin comic strips that arrive weekly on our
          site
        </h6>
        <p>
          When sharing these comics plase try and credit the author or post a
          link to this site.
        </p>
      </div>

      <div className="basic-strips">
        <Strips />
      </div>
      <button
        className={`scroll-to-top ${isVisible ? "show" : ""}`}
        onClick={scrollToTop}
      >
        <FaArrowCircleUp />
      </button>
      <div className="basic-footer">
        <Footer />
      </div>
    </div>
  );
};

export default BasicSite;
