import "./toggleswitch.css";
import cube_icon from "../../assets/cube.webp";
import algo_logo from "../../assets/algo.webp";

const ToggleSwitch = ({ value, onChange }) => {
  const isAlgos = value === "algos";

  const handleToggle = () => {
    const newValue = isAlgos ? "cubes" : "algos";
    onChange({ target: { name: "type", value: newValue } });
  };

  return (
    <div
      className={`toggle-container ${
        isAlgos ? "algos-active" : "cubes-active"
      }`}
      onClick={handleToggle}
    >
      <div className="toggle-switch">
        <div className="toggle-slider">
          <div className="image-container">
            <img
              src={cube_icon}
              alt="cube"
              className={`cube-icon ${!isAlgos ? "visible" : "hidden"}`}
            />
            <img
              src={algo_logo}
              alt="algo"
              className={`algo-icon ${isAlgos ? "visible" : "hidden"}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
