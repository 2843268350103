import axios from "axios";
import { MdDownload } from "react-icons/md";
import DynamicImage from "../utils/DynamicAssetImages";
import { useEffect, useState, useRef } from "react";
import algo_logo from "../../assets/algo.webp";
import cube_icon from "../../assets/cube.webp";
import loadingGIF from "../../assets/loading.gif";
import { SelectField } from "../customSelect.tsx";
import { toast } from "react-toastify";
import algosdk from "algosdk";
import { encode, decode } from "uint8-to-base64";
import { decodeUnsignedTransaction } from "algosdk";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBitcoin } from "react-icons/fa";
import { RiRobot2Line } from "react-icons/ri";
import { IoDiamond } from "react-icons/io5";
import { IoBody } from "react-icons/io5";
import {
  API_ENDPOINT,
  API_ENDPOINT2,
  BASIC_TRAIT_USD_FEE,
  BASIC_TRAIT_CUBE_FEE,
  CREATOR_WALLET,
  FULL_MENTAL_MARVIN_RESERVE,
  IPFS_PREFIX,
  NODE_ENDPOINT,
  SPECIAL_TRAIT_USD_FEE,
  SPECIAL_TRAIT_CUBE_FEE,
  ASSET_NAMES,
} from "../../constants.ts";
import {
  getAssetsFromAddress,
  getBalance,
  trait_shop_check_tx,
  force_cube_optin,
  downloadAsset,
  fetchImageData,
  getAlgoPrice,
} from "../utilities.ts";
import AssetImage from "../asset_image.tsx";
import "./traitshop.css";
import React from "react";
import { useWallet } from "@txnlab/use-wallet";
import { SpecialTraitBenefitsProps } from "./special_trait_benefits.tsx";
import marvin3 from "../../assets/marvin/marvin3.webp";
import TraitBox from "../traitbox.tsx";
import "./accordion.css";
import { FaHandPaper } from "react-icons/fa";
import { FaTshirt } from "react-icons/fa";
import { GiBandit, GiMouthWatering } from "react-icons/gi";

import { BsFillStarFill } from "react-icons/bs";
import { FaPiedPiperSquare } from "react-icons/fa";
import success from "../../assets/sounds/success.mp3";
import { IoEyeSharp } from "react-icons/io5";
import ConnectDropdown from "../ConnectDropdown";
import { useWalletInfo } from "../utils/UserWalletHook.js";
import DiscordShare from "../utils/DiscordShare.js";
import Loader from "../Loader.js";

export type TraitShopTrait = {
  includes(item: TraitShopTrait): unknown;
  id: number;
  trait_type: string;
  trait_name: string;
  ipfs_cid: string;
  description?: string;
  season: number;
  trinket_requirement__name?: string;
  trinket_requirement__reward_id?: number;
  trinket_requirement__image_cid?: string;
  rarity: number;
};
/* interface Trait {
  key: string;
  value: string;
}
 */
const traitTypes = ["All", "Hair", "Clothes", "Hand"];
const testAssets = [1017947273, 1018074433, 1017982745, 1018095567];
const AccordionItem = ({ title, isOpen, onClick, children }) => {
  return (
    <div
      className={`trait-accordion ${isOpen ? "open" : ""}`}
      onClick={onClick}
    >
      <div className={`trait-accordion-header ${isOpen ? "open" : ""}`}>
        <h3>{title}</h3>
      </div>
      <div className={`trait-accordion-content ${isOpen ? "open" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default function TraitShop() {
  const { walletAddress } = useWalletInfo();
  const [selectedSeason, setSelectedSeason] = useState(2);
  const [openIndex, setOpenIndex] = useState(0);
  const [newMarvin, setNewMarvin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [traitData, setTraitData] = useState([]);
  const [swapedTraits, setSwappedTraits] = useState([]);
  const [userAssets, setUserAssets] = useState([] as number[]);
  const [userBalance, setUserBalance] = useState([0, 0]);
  const [alreadySpecial, setAlreadySpecial] = useState(false);
  const [selectedMarvin, setSelectedMarvin] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [transactions, setTransactions] = useState([] as any[]);
  const { activeAccount, signTransactions, sendTransactions } = useWallet();
  const [description, setDescription] = useState<any>({});
  const [triggerReward, setTriggerReward] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [data, setData] = useState({
    basic: [] as TraitShopTrait[],
    special: [] as TraitShopTrait[],
  });
  const [newAssetIPFSCID, setNewAssetIPFSCID] = useState("");
  const [selectedBasicTrait, setSelectedBasicTrait] = useState(
    {} as TraitShopTrait
  );
  const [selectedSpecialTrait, setSelectedSpecialTrait] = useState(
    {} as TraitShopTrait
  );
  const [specialTraitBenefits, setSpecialTraitBenefits] = useState<
    SpecialTraitBenefitsProps[]
  >([]);
  const [isSwapButtonClickable, setIsSwapButtonClickable] = useState(false);
  const [selectedTraitType, setSelectedTraitType] = useState("All");
  const traitsLoaded = useRef(false); // Track if traits have already been loaded
  const [prices, setPrices] = useState<number[]>([0, 0]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const usdPrice = await getAlgoPrice(); // Fetch USD price of ALGO
        const calculatedPrices = [
          BASIC_TRAIT_USD_FEE / usdPrice,
          SPECIAL_TRAIT_USD_FEE / usdPrice,
        ];
        setPrices(calculatedPrices); // Set calculated prices
      } catch (error) {
        console.error("Error calculating prices:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPrices();
  }, []); // No dependencies to ensure it only runs once

  useEffect(() => {
    traitsLoaded.current = false; // Reset on Marvin change
  }, [selectedMarvin]);

  const handleTraitsReceived = (traits) => {
    // Check if traits have already been loaded to avoid spam
    if (!traitsLoaded.current) {
      setTraitData(traits);
      setSwappedTraits(traits); // Set the initial swappedTraits to match traitData
      traitsLoaded.current = true; // Mark as loaded to prevent future updates
    }
  };

  const handleAccordionClick = (index) => {
    setOpenIndex(index);
  };

  const playSound = (sound) => {
    const audio = new Audio(sound);
    audio.play().catch((error) => {
      console.error("Error playing sound:", error);
    });
  };

  useEffect(() => {
    let isMounted = true; // Prevents calls when unmounted

    const fetchData = async () => {
      try {
        const timestamp = Date.now(); // Optional if timestamp is required for caching
        const response = await axios.get(
          `${API_ENDPOINT}/trait_shop/?_=${timestamp}`
        );

        if (isMounted) {
          const data = response.data;
          data.basic.sort((a, b) => a.trait_type.localeCompare(b.trait_type));

          setData(data);
        }
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    // Cleanup to prevent multiple API calls
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array ensures it only runs once

  useEffect(() => {
    if (traitData["Special1"] && traitData["Special1"] !== "none") {
      setAlreadySpecial(true);
    } else {
      setAlreadySpecial(false);
    }
  }, [traitData]);

  const toggleSeason = () => {
    setSelectedSeason((prevSeason) => (prevSeason === 2 ? 1 : 2));
  };

  const getRarityClass = (rarity) => {
    if (rarity === 0) return "rarity-1";
    if (rarity > 0 && rarity <= 1) return "rarity-2";
    if (rarity > 1 && rarity <= 3) return "rarity-3";
    return "rarity-4"; // rarity > 3
  };

  useEffect(() => {
    // Update the clickability status whenever relevant conditions change
    setIsSwapButtonClickable(
      selectedMarvin !== "" &&
        (selectedBasicTrait.id !== undefined ||
          selectedSpecialTrait.id !== undefined)
    );
  }, [selectedMarvin, selectedBasicTrait, selectedSpecialTrait]);

  useEffect(() => {
    // Check if selectedSpecialTrait is not null and specialTraitBenefits has data
    if (selectedSpecialTrait && specialTraitBenefits.length > 0) {
      // Find the special trait benefit object with the same trait_name as selectedSpecialTrait
      const matchingBenefit = specialTraitBenefits.find(
        (benefit) => benefit.trait_name === selectedSpecialTrait.trait_name
      );

      // Display the description of the matching special trait benefit
      if (matchingBenefit) {
        setDescription(matchingBenefit);
      } else {
        setDescription(""); // Reset description if no match is found
      }
    }
  }, [selectedSpecialTrait, specialTraitBenefits]);

  useEffect(() => {
    if (walletAddress !== "") {
      getAssetsFromAddress(walletAddress).then((res) => {
        setUserAssets(res);
        const timestamp = Date.now();
        async function getSpecialTraitBenefits() {
          const response = await axios.get(
            `${API_ENDPOINT}/special_trait_benefits/?_=${timestamp}`
          );

          setSpecialTraitBenefits(response.data);
        }
        getSpecialTraitBenefits();

        getBalance(walletAddress).then((res) => {
          setUserBalance(res);
          setLoading(false);
        });
      });
    }
  }, [walletAddress]);

  const updateSelectedMarvin = (asset_id: string) => {
    setSelectedMarvin(asset_id.toString());
    if (data.basic.length === 0) {
      axios
        .get(API_ENDPOINT + "/trait_shop/")
        .then((res) => {
          let data = res.data;
          data.basic.sort((a: TraitShopTrait, b: TraitShopTrait) =>
            a.trait_type.localeCompare(b.trait_type)
          );

          setData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if select empty, reset all things
    if (asset_id === "") {
      setSelectedBasicTrait({} as TraitShopTrait);
      setSelectedSpecialTrait({} as TraitShopTrait);
    }
  };

  const resetAsset = (setAsset, assetValue) => {
    // Temporarily clear the asset
    setAsset(null);

    // Reset the asset after a brief delay (e.g., 50 ms)
    setTimeout(() => {
      setAsset(assetValue);
    }, 50);
  };

  function calculateCost() {
    let algo = 0;
    let fc = 0;
    let usd = 0;

    // Ensure that prices have valid values before calculating
    if (prices.length > 1) {
      if (
        selectedBasicTrait.id !== undefined &&
        selectedSpecialTrait.id !== undefined
      ) {
        algo = prices[0] + prices[1];
        fc = BASIC_TRAIT_CUBE_FEE + SPECIAL_TRAIT_CUBE_FEE;
      } else if (selectedBasicTrait.id !== undefined) {
        algo = prices[0];
        fc = BASIC_TRAIT_CUBE_FEE;
        usd = BASIC_TRAIT_USD_FEE;
      } else if (selectedSpecialTrait.id !== undefined) {
        algo = prices[1];
        fc = SPECIAL_TRAIT_CUBE_FEE;
        usd = SPECIAL_TRAIT_USD_FEE;
      }
    }

    return {
      algo,
      fc,
      usd,
    };
  }

  const handleImageDownload = async () => {
    if (selectedMarvin) {
      const data = await fetchImageData(selectedMarvin);

      if (data && data.url) {
        // Ensure `data` is not null and has a `url` property
        const { url } = data;
        const assetName = ASSET_NAMES[selectedMarvin];

        downloadAsset(url, assetName);
      } else {
        console.error("Failed to fetch image data or URL is missing.");
      }
    }
  };

  async function checkShop() {
    if (selectedMarvin === "") {
      toast.info("Please select a Marvin!");
      return;
    }
    if (
      selectedBasicTrait.id === undefined &&
      selectedSpecialTrait.id === undefined
    ) {
      toast.info("Please select at least one trait to swap!");
      return;
    }
    setIsClicked(true);
    try {
      const selected_asset_response = await axios.get(
        `${NODE_ENDPOINT}/v2/assets/${selectedMarvin}`
      );

      if (
        selected_asset_response.data.params.reserve ===
        FULL_MENTAL_MARVIN_RESERVE
      ) {
        toast.error("You can't use the Trait Shop a full mental Marvin!");
        setIsClicked(false);
        return;
      }

      if (selected_asset_response.data.params.creator !== CREATOR_WALLET) {
        toast.error("You selected invalid asset!");
        setIsClicked(false);
        return;
      }

      const rebalance = await getBalance(walletAddress);

      const totalCost = calculateCost();
      setUserBalance(rebalance);
      if (userBalance[0] < totalCost.algo) {
        toast.error("You don't have enough ALGO to pay for the swap fee!");
        setIsClicked(false);
        return;
      }
      /* if (userBalance[1] < totalCost.fc) {
        toast.error("You don't have enough FC to pay for the swap fee!");
        setIsClicked(false);
        return;
      } */

      const transaction = await trait_shop_check_tx(
        walletAddress,
        parseInt(selectedMarvin),
        selectedBasicTrait,
        selectedSpecialTrait
      );

      if (!transaction) {
        toast.error(
          "Something went wrong while creating the check transaction!"
        );
        setIsClicked(false);
        return;
      }
      toast.info("Please sign the transaction");
      // Convert algosdk.Tx to Uint8Array
      const encodedTransaction = algosdk.encodeUnsignedTransaction(transaction);

      // Sign transactions using useWallet module
      const signedTransactions = await signTransactions([encodedTransaction]);

      // Extract signed transaction
      const signedTxn = signedTransactions[0];

      // Encode the signed transaction
      const encodedTxn = encode(signedTxn);

      if (encodedTxn) {
        toast.info("Please wait while checking your request...");
        const res = await axios.post(`${API_ENDPOINT}/trait_shop/`, {
          tx: encodedTxn,
        });
        if (res.status === 200) {
          const transactions = res.data.transactions;
          setNewAssetIPFSCID(res.data.cid);
          if (transactions.length < 2 || transactions.length > 3) {
            toast.error("Something went wrong while creating the swap!");
            setIsClicked(false);
            return;
          }
          setIsClicked(false);

          setTransactions(transactions);
        }
      } else {
        toast.error("Something went wrong while creating the swap!");
        setIsClicked(false);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  async function completeShop() {
    if (!transactions || transactions.length < 2 || transactions.length > 3) {
      toast.error("Something went wrong while creating the swap!");
      return;
    }
    setIsClicked(true);
    try {
      let txnsToValidate = [] as Uint8Array[];

      let selected_asset_config_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[0], "base64")
      );

      let user_algo_payment_txn;
      let user_cube_payment_txn;
      let trinket_tx;

      if (transactions.length === 3) {
        user_algo_payment_txn = decodeUnsignedTransaction(
          Buffer.from(transactions[1], "base64")
        );
        user_cube_payment_txn = decodeUnsignedTransaction(
          Buffer.from(transactions[2], "base64")
        );
      } else if (transactions.length === 2) {
        trinket_tx = decodeUnsignedTransaction(
          Buffer.from(transactions[1], "base64") // Assuming trinket_tx is the second transaction
        );
      }
      interface TransactionGroup {
        txn: any;
        signers: string[];
      }

      const multipleTxnGroups: TransactionGroup[] = [];

      if (transactions.length === 3) {
        // If there are 3 transactions, include both user algo and cube payment transactions
        multipleTxnGroups.push(
          { txn: selected_asset_config_txn, signers: [CREATOR_WALLET] },
          { txn: user_algo_payment_txn, signers: [walletAddress] },
          { txn: user_cube_payment_txn, signers: [walletAddress] }
        );
      } else if (transactions.length === 2) {
        // If there are 2 transactions, include the trinket transaction
        multipleTxnGroups.push(
          { txn: selected_asset_config_txn, signers: [CREATOR_WALLET] },
          { txn: trinket_tx, signers: [walletAddress] }
        );
      }

      //flatten the trx
      const transactionsToSign = multipleTxnGroups
        .map(({ txn }) => algosdk.encodeUnsignedTransaction(txn))
        .flat();

      //sign trx
      toast.info("Please sign the transactions to complete the swap");
      const signedTransactions = await signTransactions([transactionsToSign]);

      if (!signedTransactions) {
        toast.error("Something went wrong while signing the transactions!");
        setIsClicked(false);
        return;
      }

      txnsToValidate.push(decode(transactions[0]));

      if (transactions.length === 3) {
        // If there are 3 transactions, include both signed algo and cube payment transactions
        txnsToValidate.push(signedTransactions[1]); // Assuming signedTransactions[0] corresponds to user_algo_payment_txn
        txnsToValidate.push(signedTransactions[2]); // Assuming signedTransactions[1] corresponds to user_cube_payment_txn
      } else if (transactions.length === 2) {
        // If there are 2 transactions, include the signed trinket transaction
        txnsToValidate.push(signedTransactions[1]); // Assuming signedTransactions[0] corresponds to the trinket transaction
      }

      //encode signed trx
      const validateData = txnsToValidate.map((txn) => {
        return encode(txn);
      });

      //this is ok now
      const validate_response = await axios.post(
        `${API_ENDPOINT}/trait_shop/validate/`,
        {
          txns: validateData,
        }
      );
      if (validate_response.status === 200) {
        toast.success("Successfully completed!");
        setIsClicked(false);
        setNewMarvin(true);
        playSound(success);
        resetAsset(setSelectedMarvin, selectedMarvin);
        setTriggerReward(true);
        /* setTimeout(() => {
          window.location.reload();
        }, 2000); */
      } else {
        toast.error("Something went wrong while confirming the transactions!");
        setIsClicked(false);
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  async function FCOptin(walletAddress: string, assetID: number) {
    if (!walletAddress) {
      toast.error("Please connect your wallet first!");
      return;
    }
    setIsClicked(true);
    const transaction = await force_cube_optin(walletAddress, assetID);
    if (!transaction) {
      toast.error("Something went wrong while creating the swap request!");
      setIsClicked(false);
      return;
    }

    try {
      // Sign transaction using useWallet module
      const signedTxn = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction),
      ]);

      const txId = await sendTransactions(signedTxn);
    } catch (error) {
      // Handle errors
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  if (loading) {
    return (
      <div className="limiter">
        <div className="shopmain-wrap">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="limiter">
      <div className="shopmain-wrap">
        <div className="shopmain">
          <>
            {newAssetIPFSCID && (
              <div className="modal-overlay">
                <div className="trinket-modal-content-traitshop">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">
                    New Marvin Look
                  </h1>
                  <p></p>
                  <div className="modal-body">
                    <div
                      className={`trait-shop-img ${newMarvin ? "done" : ""}`}
                    >
                      <AssetImage
                        image_url={`${IPFS_PREFIX}/${newAssetIPFSCID}`}
                      />
                    </div>
                    <p></p>
                  </div>

                  <div className="modal-footer">
                    {newMarvin ? (
                      <div className="trait-shop-share-discord">
                        <h3>Success!!</h3>
                        <div className="discord-send-flex">
                          <div>
                            <DiscordShare
                              text="Check out this Trait Shop Mental creation from"
                              ipfsImageUrl={`${IPFS_PREFIX}/${newAssetIPFSCID}`}
                              assetName={ASSET_NAMES[selectedMarvin]}
                              address={walletAddress}
                              // IPFS URL as a link
                            />
                          </div>

                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => {
                              setNewAssetIPFSCID("");
                              setTransactions([]);
                              setNewMarvin(false);
                              setTriggerReward(false);
                            }}
                          >
                            CLOSE
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        {isClicked ? (
                          <button
                            className="btn btn-info"
                            type="button"
                            disabled
                          >
                            <div
                              className="spinner-border text-light mr-2"
                              role="status"
                            ></div>
                          </button>
                        ) : (
                          <span>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={completeShop}
                            >
                              Complete
                            </button>
                            &nbsp; to apply the new look
                          </span>
                        )}
                        {isClicked ? (
                          <></>
                        ) : (
                          <span>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => {
                                setNewAssetIPFSCID("");
                                setTransactions([]);
                              }}
                            >
                              Reject
                            </button>
                            &nbsp;to try something else.
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>{" "}
          <div className="maincontainer">
            {/* marvin selector */}
            <div className="leftpart">
              <div className="trait-shop-select">
                <SelectField
                  text="a Marvin"
                  selectedAsset={selectedMarvin}
                  setSelectedAsset={updateSelectedMarvin}
                  assets={walletAddress === "" ? testAssets : userAssets}
                />
                <div className="imagestretch">
                  {selectedMarvin !== "" ? (
                    <div className="swapperimage-dynamic">
                      <a
                        className="trait-img-download"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default link behavior
                          handleImageDownload(); // Pass the asset ID to download the image
                        }}
                      >
                        <MdDownload />
                      </a>

                      <img
                        src="/traits/Clothes/none.webp"
                        alt="marvin"
                        className="marvin3"
                      />
                      <DynamicImage traitData={traitData} />
                    </div>
                  ) : (
                    <div>
                      <div className="swapperimage">
                        <img src={marvin3} alt="marvin" className="marvin3" />
                      </div>
                    </div>
                  )}
                  <div className="traits1">
                    <div
                      className="accordion swapper-accordion"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h5 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            TRAITS
                          </button>
                        </h5>

                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            {selectedMarvin ? (
                              <TraitBox
                                asset_id={selectedMarvin}
                                onTraitsReceived={handleTraitsReceived}
                              />
                            ) : (
                              <p></p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* cost info */}

              <div className="shopcost">
                {!walletAddress ? (
                  <>
                    <div className="showcase-description">
                      This is just a showcase. If you want to do buy traits for
                      your Marvin you need to connect your Wallet.
                    </div>
                    <h6>Connect here</h6>
                    <div className="costmobile">
                      <div className="not-connected-button">
                        <ConnectDropdown
                          isOpen={dropdownOpen}
                          onClose={() => setDropdownOpen(false)}
                          ref={dropdownRef}
                        />
                      </div>
                    </div>

                    <div className="marketwrapper">
                      <h6>
                        If you need Marvin's you can find the secondary markets
                        here.
                      </h6>
                      <a
                        href="https://www.minthol.art/3%3A0_1643/assets/all?listingTypes=BUY&listingTypes=BID"
                        target="blank"
                        className="marketlink"
                      >
                        <img src="/minthol.jpg" alt="minthol.art" />{" "}
                      </a>
                      <a
                        href="https://www.asalytic.app/collection/mental-marvin?tab=market&traitTypes=&traitValues=&filterTypes=&filterValues="
                        target="blank"
                        className="marketlink"
                      >
                        <img src="/asalytic.png" alt="asalytic.app" />{" "}
                      </a>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="description">
                      {" "}
                      <div className="trait-requirements">
                        <h5>DESCRIPTION</h5>
                        <a>
                          {selectedBasicTrait.description}
                          {selectedSpecialTrait.description}
                        </a>
                      </div>
                      <div className="copytoken-wrap">
                        <h6>EXTRA BENEFITS</h6>
                        <div className="copytokenid">
                          {selectedSpecialTrait.trait_name ? (
                            description &&
                            Object.keys(description).length > 0 ? (
                              <>
                                <div className="copytoken-description">
                                  {" "}
                                  {description.description}
                                </div>
                                <div className="coin-wrap">
                                  <img
                                    className="coin"
                                    src={
                                      IPFS_PREFIX +
                                      "/" +
                                      description.token_image
                                    }
                                    alt={`${description.token_name}_image`}
                                  />

                                  {/* <button
                                type="button"
                                className="btn btn-secondary btn-sm"
                                onClick={() =>
                                  FCOptin(walletAddress, description.token_id)
                                } 
                                disabled={isClicked}
                              >
                                {isClicked ? "Processing..." : "OPT-IN"}
                              </button> */}
                                </div>
                              </>
                            ) : (
                              <div className="copytoken-nodescription">
                                No current benefits.
                              </div>
                            )
                          ) : (
                            <div className="copytoken-nodescription">
                              Some special traits have extra benefits
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="costmobile">
                      {selectedSpecialTrait.trinket_requirement__name ||
                      selectedBasicTrait.trinket_requirement__name ? (
                        <div className="trait-trinket-cost">
                          <h4>COST</h4>
                          <img
                            src={`${IPFS_PREFIX}/${
                              selectedSpecialTrait.trinket_requirement__image_cid ||
                              selectedBasicTrait.trinket_requirement__image_cid
                            }`}
                            alt={
                              selectedSpecialTrait.trinket_requirement__name ||
                              selectedBasicTrait.trinket_requirement__name
                            }
                          />

                          <h5>
                            {selectedSpecialTrait.trinket_requirement__name ||
                              selectedBasicTrait.trinket_requirement__name}{" "}
                            <span>JUNK</span>
                          </h5>
                        </div>
                      ) : (
                        <div className="trait-priceshow">
                          <h4>COST</h4>

                          <div className="algo">
                            <img src={algo_logo} alt="algo" />
                            {prices.length > 1 && !loading
                              ? calculateCost().algo.toFixed(2)
                              : "--"}
                          </div>

                          <div className="algo">
                            <div className="usd">
                              <AiFillDollarCircle />
                              {loading ? "--" : calculateCost().usd}
                            </div>
                          </div>

                          <div className="fccube">
                            <img src={cube_icon} alt="cube" />
                            {loading ? "--" : calculateCost().fc}
                          </div>
                        </div>
                      )}

                      {isClicked ? (
                        <button className="btn btn-info" type="button" disabled>
                          <div
                            className="spinner-border text-light mr-2"
                            role="status"
                          ></div>
                        </button>
                      ) : (
                        <>
                          {transactions.length !== 3 ? (
                            <>
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={checkShop}
                                disabled={!isSwapButtonClickable}
                              >
                                BUY TRAIT
                              </button>
                            </>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-info"
                              disabled
                            >
                              Complete
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* trait selector */}
            <div className="rightpart">
              <div className="traitselector">
                <div className="trait-accordion-container">
                  <AccordionItem
                    title="BASIC TRAITS"
                    isOpen={openIndex === 0}
                    onClick={() => handleAccordionClick(0)}
                  >
                    <div className="basic-trait-filter">
                      <select
                        id="traitTypeSelect"
                        value={selectedTraitType}
                        className="select-field"
                        onChange={(e) => setSelectedTraitType(e.target.value)}
                      >
                        {traitTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <div className="season-button">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={toggleSeason}
                      >
                        {selectedSeason === 2 ? (
                          <div className="season-change-btn">
                            <FaBitcoin /> SHIRTS
                          </div>
                        ) : (
                          <div className="season-change-btn">
                            BASIC
                            <RiRobot2Line />
                          </div>
                        )}
                      </button>
                    </div> */}

                    <div className="basictraits">
                      <ul className="trait">
                        {data.basic
                          // Filter by selected trait type, allowing "All" to show everything
                          .filter(
                            (item) =>
                              (selectedTraitType === "All" ||
                                item.trait_type === selectedTraitType) &&
                              item.season === 2
                          )
                          // Additional filtering based on existing conditions
                          .filter(
                            (item) =>
                              (!item.trinket_requirement__name ||
                                item.trinket_requirement__name.trim() === "") &&
                              item.trait_type !== "Background"
                          )
                          .map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={`traititem ${
                                  selectedBasicTrait.id === item.id
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <img
                                  key={item.id}
                                  src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                  alt={item.trait_name}
                                  onClick={() => {
                                    if (item.id === selectedBasicTrait.id) {
                                      setSelectedBasicTrait(
                                        {} as TraitShopTrait
                                      );
                                      setTraitData(swapedTraits);
                                      return;
                                    }

                                    setSelectedBasicTrait(item);
                                    setSelectedSpecialTrait(
                                      {} as TraitShopTrait
                                    );
                                    setTraitData(swapedTraits);
                                    setTraitData((prevTraits) => ({
                                      ...prevTraits,
                                      [item.trait_type]: item.trait_name, // Update specific trait type
                                    }));
                                  }}
                                />
                                <h5>{item.trait_name}</h5>
                                {item.trait_type === "Hand" && (
                                  <div className="trait-type-icon">
                                    <FaHandPaper />
                                  </div>
                                )}
                                {item.trait_type === "Clothes" && (
                                  <div className="trait-type-icon">
                                    <FaTshirt />
                                  </div>
                                )}
                                {item.trait_type === "Hair" && (
                                  <div className="trait-type-icon">
                                    <GiBandit />
                                  </div>
                                )}
                                {item.trait_type === "Background" && (
                                  <div className="trait-type-icon">
                                    <FaPiedPiperSquare />
                                  </div>
                                )}
                                <div className={`rarity-box`}>
                                  <IoDiamond />
                                  <span
                                    className={`rarity-text ${getRarityClass(
                                      item.rarity
                                    )}`}
                                  >
                                    {item.rarity}%
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </AccordionItem>
                  <AccordionItem
                    title="TRAIT SHIRTS"
                    isOpen={openIndex === 1}
                    onClick={() => handleAccordionClick(1)}
                  >
                    <div className="basictraits">
                      <ul className="trait">
                        {data.basic
                          // Filter by selected trait type, allowing "All" to show everything
                          .filter(
                            (item) =>
                              item.season === 99 &&
                              item.trait_type === "Clothes"
                          )
                          // Additional filtering based on existing conditions

                          .map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={`traititem ${
                                  selectedBasicTrait.id === item.id
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <img
                                  key={item.id}
                                  src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                  alt={item.trait_name}
                                  onClick={() => {
                                    if (item.id === selectedBasicTrait.id) {
                                      setSelectedBasicTrait(
                                        {} as TraitShopTrait
                                      );
                                      setTraitData(swapedTraits);
                                      return;
                                    }

                                    setSelectedBasicTrait(item);
                                    setSelectedSpecialTrait(
                                      {} as TraitShopTrait
                                    );
                                    setTraitData(swapedTraits);
                                    setTraitData((prevTraits) => ({
                                      ...prevTraits,
                                      [item.trait_type]: item.trait_name, // Update specific trait type
                                    }));
                                  }}
                                />
                                <h5>{item.trait_name}</h5>

                                {item.trait_type === "Clothes" && (
                                  <div className="trait-type-icon">
                                    <FaTshirt />
                                  </div>
                                )}
                                {item.trait_type === "Hand" && (
                                  <div className="trait-type-icon">
                                    <FaHandPaper />
                                  </div>
                                )}
                                {item.trait_type === "Body" && (
                                  <div className="trait-type-icon">
                                    <IoBody />
                                  </div>
                                )}
                                {item.trait_type === "Hair" && (
                                  <div className="trait-type-icon">
                                    <GiBandit />
                                  </div>
                                )}
                                {item.trait_type === "Mouth" && (
                                  <div className="trait-type-icon">
                                    <GiMouthWatering />
                                  </div>
                                )}
                                <div className={`rarity-box`}>
                                  <IoDiamond />
                                  <span
                                    className={`rarity-text ${getRarityClass(
                                      item.rarity
                                    )}`}
                                  >
                                    {item.rarity}%
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </AccordionItem>
                  <AccordionItem
                    title="BACKGROUNDS"
                    isOpen={openIndex === 2}
                    onClick={() => handleAccordionClick(2)}
                  >
                    <div className="basictraits">
                      <ul className="trait">
                        {data.basic
                          // Filter by selected trait type, allowing "All" to show everything
                          .filter((item) => item.trait_type === "Background")
                          // Additional filtering based on existing conditions
                          .filter(
                            (item) =>
                              !item.trinket_requirement__name ||
                              item.trinket_requirement__name.trim() === ""
                          )
                          .map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={`traititem ${
                                  selectedBasicTrait.id === item.id
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <img
                                  key={item.id}
                                  src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                  alt={item.trait_name}
                                  onClick={() => {
                                    if (item.id === selectedBasicTrait.id) {
                                      setSelectedBasicTrait(
                                        {} as TraitShopTrait
                                      );
                                      setTraitData(swapedTraits);
                                      return;
                                    }
                                    setSelectedBasicTrait(item);
                                    setSelectedSpecialTrait(
                                      {} as TraitShopTrait
                                    );
                                    setTraitData(swapedTraits);
                                    setTraitData((prevTraits) => ({
                                      ...prevTraits,
                                      [item.trait_type]: item.trait_name, // Update specific trait type
                                    }));
                                  }}
                                />
                                <h5>{item.trait_name}</h5>
                                {item.trait_type === "Hand" && (
                                  <div className="trait-type-icon">
                                    <FaHandPaper />
                                  </div>
                                )}
                                {item.trait_type === "Clothes" && (
                                  <div className="trait-type-icon">
                                    <FaTshirt />
                                  </div>
                                )}
                                {item.trait_type === "Hair" && (
                                  <div className="trait-type-icon">
                                    <GiBandit />
                                  </div>
                                )}
                                {item.trait_type === "Background" && (
                                  <div className="trait-type-icon">
                                    <FaPiedPiperSquare />
                                  </div>
                                )}
                                <div className={`rarity-box`}>
                                  <IoDiamond />
                                  <span
                                    className={`rarity-text ${getRarityClass(
                                      item.rarity
                                    )}`}
                                  >
                                    {item.rarity}%
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </ul>
                    </div>
                  </AccordionItem>
                  <AccordionItem
                    title="SPECIAL TRAITS"
                    isOpen={openIndex === 3}
                    onClick={() => handleAccordionClick(3)}
                  >
                    <div className="specialtraits">
                      <div className="trait">
                        {data.special.map((item, _i) => (
                          <div
                            key={item.id}
                            className={`traititem ${
                              selectedSpecialTrait.id === item.id
                                ? "selected"
                                : ""
                            }`}
                          >
                            <img
                              className={
                                "w-24 h-24 border-2 rounded-xl" +
                                (item.id !== selectedSpecialTrait.id
                                  ? " grayscale"
                                  : " border-primary-green")
                              }
                              key={item.id}
                              src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                              alt={item.trait_name}
                              onClick={() => {
                                if (item.id === selectedSpecialTrait.id) {
                                  setSelectedSpecialTrait({} as TraitShopTrait);
                                  setTraitData(swapedTraits);
                                  return;
                                }
                                setSelectedSpecialTrait(item);
                                setSelectedBasicTrait({} as TraitShopTrait);
                                calculateCost();
                                setTraitData(swapedTraits);
                                setTraitData((prevTraits) => ({
                                  ...prevTraits,
                                  [item.trait_type]: item.trait_name, // Update specific trait type
                                }));
                              }}
                            />
                            <h5>{item.trait_name}</h5>
                            {item.trait_type === "Special1" && (
                              <div className="trait-type-icon">
                                <BsFillStarFill />
                              </div>
                            )}
                            <div className={`rarity-box`}>
                              <IoDiamond />
                              <span
                                className={`rarity-text ${getRarityClass(
                                  item.rarity
                                )}`}
                              >
                                {item.rarity}%
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="specialwarning">
                        {alreadySpecial && (
                          <>
                            <h5>WARNING!!</h5>
                            <p>
                              Putting a new Special will replace the current
                              one.
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionItem>

                  <AccordionItem
                    title="JUNK TRAITS"
                    isOpen={openIndex === 4}
                    onClick={() => handleAccordionClick(4)}
                  >
                    <div className="specialtraits">
                      <h6>Basic Slot</h6>
                      <ul className="trait">
                        {data.basic
                          .filter(
                            (item) =>
                              item.trinket_requirement__name &&
                              item.trinket_requirement__name.trim() !== ""
                          ) // Filter items that have the property `trinket_requirement__name`
                          .map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={`traititem ${
                                  selectedBasicTrait.id === item.id
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                <img
                                  key={item.id}
                                  src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                  alt={item.trait_name}
                                  onClick={() => {
                                    if (item.id === selectedBasicTrait.id) {
                                      setSelectedBasicTrait(
                                        {} as TraitShopTrait
                                      );
                                      setTraitData(swapedTraits);
                                      return;
                                    }
                                    setSelectedBasicTrait(item);
                                    setSelectedSpecialTrait(
                                      {} as TraitShopTrait
                                    );
                                    setTraitData(swapedTraits);
                                    setTraitData((prevTraits) => ({
                                      ...prevTraits,
                                      [item.trait_type]: item.trait_name, // Update specific trait type
                                    }));
                                  }}
                                />
                                <h5>{item.trait_name}</h5>
                                {item.trait_type === "Hand" && (
                                  <div className="trait-type-icon">
                                    <FaHandPaper />
                                  </div>
                                )}
                                {item.trait_type === "Clothes" && (
                                  <div className="trait-type-icon">
                                    <FaTshirt />
                                  </div>
                                )}
                                {item.trait_type === "Hair" && (
                                  <div className="trait-type-icon">
                                    <GiBandit />
                                  </div>
                                )}
                                {item.trait_type === "Background" && (
                                  <div className="trait-type-icon">
                                    <FaPiedPiperSquare />
                                  </div>
                                )}
                                {item.trait_type === "Eyes" && (
                                  <div className="trait-type-icon">
                                    <IoEyeSharp />
                                  </div>
                                )}
                                <div className={`rarity-box`}>
                                  <IoDiamond />
                                  <span
                                    className={`rarity-text ${getRarityClass(
                                      item.rarity
                                    )}`}
                                  >
                                    {item.rarity}%
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </ul>
                      <h6>Special Slot</h6>
                      <div className="trait">
                        {data.special
                          .filter(
                            (item) =>
                              item.trinket_requirement__name &&
                              item.trinket_requirement__name.trim() !== ""
                          ) // Filter items that have the property `trinket_requirement__name`
                          .map((item, _i) => (
                            <div
                              key={item.id}
                              className={`traititem ${
                                selectedSpecialTrait.id === item.id
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              <img
                                className={
                                  "w-24 h-24 border-2 rounded-xl" +
                                  (item.id !== selectedSpecialTrait.id
                                    ? " grayscale"
                                    : " border-primary-green")
                                }
                                key={item.id}
                                src={`${IPFS_PREFIX}/${item.ipfs_cid}`}
                                alt={item.trait_name}
                                onClick={() => {
                                  if (item.id === selectedSpecialTrait.id) {
                                    setSelectedSpecialTrait(
                                      {} as TraitShopTrait
                                    );
                                    setTraitData(swapedTraits);
                                    return;
                                  }
                                  setSelectedSpecialTrait(item);
                                  setSelectedBasicTrait({} as TraitShopTrait);
                                  calculateCost();
                                  setTraitData(swapedTraits);
                                  setTraitData((prevTraits) => ({
                                    ...prevTraits,
                                    [item.trait_type]: item.trait_name, // Update specific trait type
                                  }));
                                }}
                              />
                              <h5>{item.trait_name}</h5>
                              {item.trait_type === "Special1" && (
                                <div className="trait-type-icon">
                                  <BsFillStarFill />
                                </div>
                              )}
                              <div className={`rarity-box`}>
                                <IoDiamond />
                                <span
                                  className={`rarity-text ${getRarityClass(
                                    item.rarity
                                  )}`}
                                >
                                  {item.rarity}%
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="specialwarning">
                        {alreadySpecial && (
                          <>
                            <h5>WARNING!!</h5>
                            <p>
                              Putting a new Special will replace the current
                              one.
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionItem>
                </div>
              </div>

              {/*  <button
                className="btn btn-primary"
                onClick={() =>
                  setNewAssetIPFSCID(
                    "QmR4vh3Ffz6g8Tja5Ct2vGP2hBkQsq5FwcHQtyYh7kDHHQ"
                  )
                }
              >
                OPEN MODAL
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
