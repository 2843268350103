import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { IPFS_PREFIX, API_ENDPOINT2, API_ENDPOINT } from "../../constants.ts";
import "./trinketmodal.css";
import { GrStatusInfo } from "react-icons/gr";
import { useWallet } from "@txnlab/use-wallet";
import algosdk from "algosdk";
import { decodeUnsignedTransaction } from "algosdk";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import success from "../../assets/sounds/success.mp3";
import { FaLink } from "react-icons/fa6";
import AssetBoxForSwap from "../asset_box_for_swap.tsx";
import { FaRegPaste } from "react-icons/fa6";
import ToggleSwitch from "../utils/ToggleSwitch.js";
import "./makerafflemodal.css";

const MakeRaffleModal = ({ isOpen, onClose, wallet, fetchRaffleData }) => {
  const { signTransactions } = useWallet();
  const [txId, setTxId] = useState("");
  const [reward, setReward] = useState("");
  const [rewardName, setRewardName] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const formRef = useRef(null);
  const [showInfo, setShowInfo] = useState(false);
  const [raffleData, setRaffleData] = useState({
    raffleName: "",
    description: "",
    type: "cubes", // Default to 'cubes'
    endTime: "",
    raffleReward: "",
  });

  const playSound = () => {
    const audio = new Audio(success);
    audio.play();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRaffleData({ ...raffleData, [name]: value });
  };

  const handleNameReceived = (name) => {
    setRewardName(name); // Directly store the name
  };

  const handleSetReward = () => {
    if (!raffleData.raffleReward) {
      alert("Please enter a valid reward ID.");
      return;
    }

    setReward(raffleData.raffleReward); // Update the reward state with the entered value
  };

  // Function to paste number from clipboard and update input value
  const handleCopyFromClipboard = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();

      // Validate: ensure clipboard content is a number and no more than 12 digits
      if (/^\d{1,12}$/.test(clipboardText)) {
        setRaffleData((prevData) => ({
          ...prevData,
          raffleReward: clipboardText, // Set the clipboard number to the input field
        }));
      } else {
        alert(
          "Clipboard content is not a valid number or exceeds 12 characters."
        );
      }
    } catch (err) {
      alert("Failed to read clipboard contents: " + err);
    }
  };

  const handleClearData = () => {
    setRaffleData({
      raffleName: "",
      description: "",
      type: "cubes", // Default to 'cubes'
      endTime: "",
      raffleReward: "",
    });
    setReward("");
    setRewardName(""); // Clear reward for AssetBoxForSwap
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page refresh

    if (!formRef.current.reportValidity()) {
      // This will trigger browser validation and focus on missing fields
      return;
    }

    setIsSubmitting(true);

    try {
      // Simulate Algorand transaction delay
      toast.info("Validating Raffle");
      const res = await axios.get(`${API_ENDPOINT}/make-raffle/`, {
        params: {
          wallet,
          ...raffleData, // Sending raffle data in GET request
        },
      });
      if (res.status !== 200) {
        throw new Error("Raffle validation failed");
      }

      const transactions = res.data.transactions;

      if (!transactions || transactions.length !== 2) {
        toast.error("Something went wrong while creating the transactions");
        return;
      }

      const raffle_reward_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[0], "base64")
      );
      const fee_txn = decodeUnsignedTransaction(
        Buffer.from(transactions[1], "base64")
      );
      toast.info("Please sign the transaction");
      const signedTransaction = await signTransactions([
        algosdk.encodeUnsignedTransaction(raffle_reward_txn),
        algosdk.encodeUnsignedTransaction(fee_txn),
      ]);

      const raffle_reward_txn_encoded = encode(signedTransaction[0]);
      const fee_txn_encoded = encode(signedTransaction[1]);

      const postRes = await axios.post(`${API_ENDPOINT}/make-raffle/`, {
        raffleData,
        raffle_reward_txn_encoded,
        fee_txn_encoded,
      });

      if (postRes.status === 200) {
        toast.success("Raffle successfully created!");
        setIsSuccess(true);
        playSound();
        fetchRaffleData();
      } else {
        toast.error("Transaction failed. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    handleClearData();
    setIsSubmitting(false);
    setIsSuccess(false);
    setShowInfo(false);
    if (formRef.current) {
      formRef.current.reset(); // Reset the form fields
    }
    onClose(); // Call the original onClose function (if it closes the modal)
  };

  if (!isOpen) return null; // Return nothing if the modal isn't open

  if (loading) return <div>Loading...</div>;

  return (
    <div className="modal-overlay ">
      <div
        className="trinket-modal-content "
        onClick={(e) => e.stopPropagation()}
      >
        <div className="closeposition">
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="info-icon" onClick={setShowInfo}>
          <GrStatusInfo />
        </div>

        <h2>RAFFLE MAKER</h2>
        {showInfo ? (
          <div className="raffle-info">
            <h5>
              To create a raffle you will need to fill all the info in the
              fields following some limitations:
            </h5>

            <ul className="raffle-info-list">
              <li>Raffle Name - Max 25 characters</li>
              <li>
                Reward Asset ID - Only accepts Mental Marvins, Algo Force,
                Ripleys and JUNK
              </li>
              <li>Description - Max 120 characters</li>
              <li>End Time - Max 5 days</li>
            </ul>
            <h6>FORCE CUBE RAFFLE</h6>
            <p>
              Uses Force Cubes as tickets and the creator gets all the Cubes
              after the raffle ends.
            </p>
            <h6>ALGO RAFFLE</h6>
            <p>
              Uses Algo as tickets and the creator gets the regular ticket ALGO
              after it ends. <span>WARNING</span> - These raffles offer users 1
              free entry by spending 5 FC. If the raffle has ONLY free entries
              it will not finish and reward will be returned to the owner.
            </p>
            <div className="raffle-fee">
              Fee to Create a raffle is 3 ALGO. <br /> All transactions are
              final.
            </div>
            <button className="btn btn-info" onClick={() => setShowInfo(false)}>
              Hide Info
            </button>
          </div>
        ) : (
          <>
            {!isSuccess ? (
              <div className="raffle-maker-box">
                <form ref={formRef} onSubmit={handleSubmit}>
                  <h4>
                    {" "}
                    <input
                      type="text"
                      id="raffleName"
                      name="raffleName"
                      value={raffleData.raffleName}
                      onChange={handleChange}
                      placeholder="Enter raffle name"
                      maxLength={22}
                      required
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      // For extra measure, some browsers need this
                      autoSave="off"
                      results="off"
                      className="input-raffle-maker input-name"
                    />
                  </h4>
                  <div className="input-raffle-reward">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={handleCopyFromClipboard}
                    >
                      <FaRegPaste />
                    </button>
                    <input
                      type="number"
                      id="raffleReward"
                      name="raffleReward"
                      value={raffleData.raffleReward}
                      onChange={handleChange}
                      placeholder="Paste or enter reward ID"
                      maxLength={20}
                      required
                      className="input-raffle-maker input-reward"
                    />
                    <button
                      type="button"
                      onClick={handleSetReward}
                      className="btn btn-secondary"
                    >
                      Set
                    </button>
                  </div>
                  <AssetBoxForSwap
                    asset_id={reward}
                    onNameReceived={handleNameReceived}
                  />
                  <div className="raffle-reward maker-reward">
                    Reward: {rewardName}{" "}
                    {reward ? (
                      <a
                        href={`https://allo.info/asset/${reward}`}
                        target="_blank"
                        rel="noreferrer"
                        className=""
                      >
                        <FaLink />
                      </a>
                    ) : (
                      <span>Select the reward to show</span>
                    )}{" "}
                  </div>
                  <div className="input-raffle-description">
                    <textarea
                      id="description"
                      name="description"
                      value={raffleData.description}
                      onChange={handleChange}
                      maxLength={120}
                      placeholder="Enter short description"
                      className="input-raffle-maker input-description"
                      required
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      // For extra measure, some browsers need this
                      autoSave="off"
                      results="off"
                    />
                  </div>

                  <div className="raffle-timer-container">
                    <label htmlFor="endTime">End Time: </label>
                    <input
                      type="datetime-local"
                      id="endTime"
                      name="endTime"
                      value={raffleData.endTime}
                      onChange={handleChange}
                      required
                      className="input-raffle-maker input-time"
                      min={new Date().toISOString().slice(0, 16)} // Current date-time (min)
                      max={new Date(Date.now() + 5 * 24 * 60 * 60 * 1000)
                        .toISOString()
                        .slice(0, 16)} // 5 days ahead (max)
                    />
                  </div>
                  <div className="raffle-type-picker">
                    <h5>Raffle Type</h5>
                    <ToggleSwitch
                      value={raffleData.type}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-raffle-buttons">
                    <button
                      className="btn btn-info"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Processing..." : "CREATE RAFFLE"}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={handleClearData}
                    >
                      RESET RAFFLE{" "}
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="success-message">
                <h2>🎉 Raffle Created Successfully! 🎉</h2>
                <p>Your transaction was completed successfully.</p>
                <button className="btn btn-success" onClick={handleClose}>
                  Close
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MakeRaffleModal;
