// RouterComponent.js
import React, { useState, useEffect } from "react";
import Home from "./pages/Home.js";
import MentalAF from "./pages/MentalAF.js";
import Marvin from "./Marvin";
import Orange from "./pages/BurnNFT.tsx";
import ComicReader from "./pages/ComicReader";
import ComicReaderWeb from "./pages/ComicReaderWeb";
import OpenGift from "./pages/OpenGift";
import Collections from "./pages/Collections.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import "./Styles.css";
import Swapper from "./pages/swapper.tsx";
import TraitShop from "./pages/trait_shop.tsx";
import Specials from "./pages/special_trait_benefits.tsx";
import Mymarvins from "./pages/Mymarvins";
import Footer from "./general/Footer.js";
import Contact from "./general/Contact.js";
import About from "./general/About.js";
import Kiosk from "./pages/Kiosk.js";
import Terms from "./general/TermsConditions.js";
import Trinkets from "./pages/Trinkets";
import KioskRouter from "./KioskRouter";
import Raffles from "./pages/Raffles";
import ComicClaim from "./pages/ComicClaim";
import Strips from "./pages/Strips";
import WelcomeScreen from "./sitevariant/WelcomeScreen.js";
import BasicSite from "./sitevariant/BasicSite.js";
import { IoIosSwitch } from "react-icons/io";

const RouterComponent = () => {
  const isMobile = window.innerWidth <= 768;
  const [siteType, setSiteType] = useState(
    localStorage.getItem("siteType") || "new"
  );

  const changeSiteType = (type) => {
    localStorage.setItem("siteType", type);
    setSiteType(type);
  };

  useEffect(() => {
    const storedSiteType = localStorage.getItem("siteType");
    if (storedSiteType) setSiteType(storedSiteType);
  }, []);

  return (
    <Router>
      <div>
        <Routes>
          {/* Comic Claim route - always accessible */}
          <Route path="/comic-claim/:key" element={<ComicClaim />} />

          {/* Welcome Screen */}
          <Route
            path="/new"
            element={<WelcomeScreen setSiteType={changeSiteType} />}
          />

          {/* Basic Site */}
          <Route
            path="/basic"
            element={<BasicSite setSiteType={changeSiteType} />}
          />

          {/* Advanced Site Routes */}
          <Route
            path="/"
            element={
              siteType !== "new" && siteType !== "basic" ? (
                <>
                  <Navbar />
                  <Outlet />
                  <Footer />
                  <ToastContainer
                    toastContainerClassName="custom-toast-container"
                    pauseOnFocusLoss={false}
                    closeOnClick
                    draggable
                    pauseOnHover={false}
                    position={isMobile ? "top-center" : "bottom-right"}
                    rtl={false}
                    hideProgressBar={false}
                    autoClose={3500}
                    newestOnTop={true}
                    toastStyle={{
                      backgroundColor: "#9DF6FB",
                      color: "black",
                      borderBottomLeftRadius: isMobile ? "10px" : "0px",
                      borderBottomRightRadius: isMobile ? "10px" : "0px",
                    }}
                  />
                  <button
                    className="switch-button advanced"
                    onClick={() => setSiteType("new")}
                  >
                    <IoIosSwitch /> <h6>SWITCH</h6>
                  </button>
                </>
              ) : (
                <Navigate to={`/${siteType}`} replace />
              )
            }
          >
            <Route index element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="/marvin/*" element={<Marvin />}>
              <Route path="mymarvins" element={<Mymarvins />} />
              <Route path="swapper" element={<Swapper />} />
              <Route path="traitshop" element={<TraitShop />} />
              <Route path="specials" element={<Specials />} />
            </Route>
            <Route path="/orange" element={<Orange />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/mentalaf" element={<MentalAF />} />
            <Route path="/kiosk/*" element={<KioskRouter />}>
              <Route path="comics" element={<Kiosk />} />
              <Route path="trinkets" element={<Trinkets />} />
              <Route path="raffles" element={<Raffles />} />
            </Route>
            <Route path="/market/*" element={<KioskRouter />}>
              <Route path="comics" element={<Kiosk />} />
              <Route path="trinkets" element={<Trinkets />} />
              <Route path="raffles" element={<Raffles />} />
              <Route path="strips" element={<Strips />} />
            </Route>
            <Route path="/collections" element={<Collections />} />
            <Route path="/reader1" element={<ComicReader />} />
            <Route path="/reader2" element={<ComicReaderWeb />} />
            <Route path="/open-gift/:token" element={<OpenGift />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default RouterComponent;
