import React, { useState, useEffect } from "react";
import useSWR from "swr";
import axios from "axios";
import { ipfsToData } from "./utilities.ts";
import { NODE_ENDPOINT } from "../constants.ts";
import AssetImage from "./asset_image.tsx";

const placeholderImageUrl = "/cube.webp";

const fetcher = async (url, retries = 5, delay = 1000, backoffFactor = 2) => {
  const attemptFetch = async (retryCount, currentDelay) => {
    try {
      const response = await axios.get(url);
      const { url: assetUrl, reserve, name } = response.data.params;
      const { url: imageUrl, traits } = await ipfsToData(assetUrl, reserve);
      return { imageUrl, traits, name };
    } catch (error) {
      if (error.response && error.response.status === 429) {
        if (retryCount > 0) {
          console.warn(
            `Received 429, retrying in ${currentDelay}ms... attempts remaining: ${
              retryCount - 1
            }`
          );
          await new Promise((res) => setTimeout(res, currentDelay));
          return attemptFetch(retryCount - 1, currentDelay * backoffFactor);
        } else {
          console.error("Error fetching data after multiple retries:", error);
          throw error;
        }
      } else {
        console.error("Error fetching data:", error);
        throw error;
      }
    }
  };
  return attemptFetch(retries, delay);
};

const AssetBoxForSwap = ({
  asset_id,
  onNameReceived,
  onTraitsReceived,
  onImageUrlReceived,
}) => {
  const [url, setUrl] = useState(placeholderImageUrl);

  const { data, error } = useSWR(
    asset_id ? `${NODE_ENDPOINT}/v2/assets/${asset_id}` : null,
    fetcher
  );

  useEffect(() => {
    if (!asset_id) {
      setUrl(placeholderImageUrl); // Reset to placeholder if no asset_id is provided
    }
    if (data) {
      const { imageUrl, traits, name } = data;

      if (imageUrl) {
        setUrl(imageUrl);
        if (onImageUrlReceived) onImageUrlReceived(imageUrl, asset_id);
      } else {
        setUrl(placeholderImageUrl);
      }
      if (onImageUrlReceived) onImageUrlReceived(imageUrl, asset_id);
      if (onNameReceived) onNameReceived(name, asset_id);
      if (onTraitsReceived) onTraitsReceived(traits, asset_id);
    }
  }, [data, onNameReceived, onTraitsReceived, onImageUrlReceived, asset_id]);

  if (error) return <div>Error loading asset</div>;

  return (
    <div>
      <AssetImage image_url={url} />
    </div>
  );
};

export default AssetBoxForSwap;
