import React, { useState } from "react";
import { useWallet } from "@txnlab/use-wallet";
import { API_ENDPOINT, CUBE_ASSET_ID } from "../../constants.ts";
import { useWalletInfo } from "./UserWalletHook.js";
import { simple_asset_transfer } from "../utilities.ts";
import { toast } from "react-toastify";
import axios from "axios";
import algosdk from "algosdk";
import { encode } from "uint8-to-base64";

const NoSignTx = ({ onCookieSet }) => {
  const { signTransactions } = useWallet();
  const { walletAddress } = useWalletInfo();
  const [clck_key, setClckKey] = useState("");

  const handleNoClickTx = async () => {
    try {
      if (!walletAddress) {
        throw new Error("No active account");
      }

      const txn = await simple_asset_transfer(walletAddress, CUBE_ASSET_ID, 0);
      if (!txn) {
        toast.error("Something went wrong while creating the swap request!");
        return;
      }

      const encodedTransaction = algosdk.encodeUnsignedTransaction(txn[0]);
      toast.info("Please sign the transaction");
      const signedTxns = await signTransactions([encodedTransaction]);
      const encodedTxn = encode(signedTxns[0]);

      const response = await axios.get(`${API_ENDPOINT}/no-sign-tx/`, {
        params: {
          signedTxns: encodedTxn,
        },
        withCredentials: true,
      });

      const clck_key = await response.data.key;
      if (clck_key) {
        setClckKey(clck_key);
        toast.success("No Sign Enabled!");
        onCookieSet(true);
        return;
      } else {
        const errorMessage = response.data || "Something went wrong!";
        toast.error(errorMessage);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
    }
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-warning"
        onClick={handleNoClickTx}
      >
        ENABLE NO SIGN SWAPS
      </button>
    </div>
  );
};

export default NoSignTx;
