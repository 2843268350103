import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { IPFS_PREFIX, API_ENDPOINT2, API_ENDPOINT } from "../../constants.ts";
import "./trinketmodal.css";
import { useWallet } from "@txnlab/use-wallet";
import algosdk from "algosdk";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import success from "../../assets/sounds/success.mp3";
import cube_icon from "../../assets/cube.webp";
import { raffle_entry_tx } from "../utilities.ts";

const MakeRaffleModal = ({
  isOpen,
  onClose,
  wallet,
  raffle,
  fetchRaffleData,
}) => {
  const { signTransactions } = useWallet();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const playSound = () => {
    const audio = new Audio(success);
    audio.play();
  };
  const raffleEntries = 5;

  const handleClose = () => {
    setDone(false);
    onClose();
  };

  if (!isOpen) return null;

  if (loading) return <div>Loading...</div>;

  async function sendRaffleEntry(raffleId, entries, asset_id, type) {
    if (!wallet) {
      toast.info("Please connect your wallet first!");
      return;
    }

    // Check if entries is a whole number
    if (!Number.isInteger(entries) || entries <= 0) {
      toast.info("The number of entries must be a whole number");
      return;
    }
    setIsClicked(true);
    toast.info("Sign the transaction to open!");
    try {
      const transaction = await raffle_entry_tx(
        wallet,
        entries,
        asset_id,
        type
      );
      if (!transaction) {
        toast.info("Something went wrong while creating the transactions!");
        setIsClicked(false);
        return;
      }
      let opt_in_tx;
      let raffle_entry_trx;

      const signedTransaction = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction[0]),
        algosdk.encodeUnsignedTransaction(transaction[1]),
      ]);
      opt_in_tx = encode(signedTransaction[0]);
      raffle_entry_trx = encode(signedTransaction[1]);

      toast.info("Sending to the raffle!");
      const res = await axios.post(`${API_ENDPOINT}/check-raffles/`, {
        opt_in_tx,
        raffle_entry_trx,
        raffleId,
      });

      if (res.status === 200) {
        toast.success("Successfully entered the raffle!");
        setDone(true);
        playSound();
        setIsClicked(false);
        fetchRaffleData();
      } else {
        toast.error("Something went wrong while raffling!");
        setIsClicked(false);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  return (
    <div className="modal-overlay ">
      <div
        className="trinket-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="closeposition">
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
        </div>

        <h2>FREE TICKET</h2>
        {!done ? (
          <div className="free-ticket">
            <div className="free-ticket-explain">
              <h5>
                Gain a one-time entry into the {raffle.raffleName} raffle by
                using your Force Cubes.
              </h5>
            </div>
            <div className="free-ticket-price">
              <h5>
                You will spend 5 <img src={cube_icon} alt="cube" /> and get 1
                entry.
              </h5>
            </div>
            {isClicked ? (
              <button className="btn btn-warning" disabled>
                <div
                  className="spinner-border text-light mr-2"
                  role="status"
                ></div>
              </button>
            ) : (
              <button
                className="btn btn-info"
                onClick={() =>
                  sendRaffleEntry(
                    raffle.raffleId,
                    raffleEntries,
                    raffle.asset_id,
                    "free"
                  )
                }
              >
                ENTER
              </button>
            )}
          </div>
        ) : (
          <div className="success-message">
            <h2>🎉 Success! 🎉</h2>
            <p>
              Your transaction was completed successfully and you entered the
              raffle.
            </p>
            <button className="btn btn-success" onClick={handleClose}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MakeRaffleModal;
