import * as constant from "../constants.ts";
import {
  Algodv2,
  makeAssetTransferTxnWithSuggestedParamsFromObject,
  Transaction,
  decodeAddress,
  computeGroupID,
  makePaymentTxnWithSuggestedParamsFromObject,
  algosToMicroalgos,
 
} from "algosdk";
import axios from "axios";
import cid from "cids";
import multihash from "multihashes";
import loadingGIF from "../assets/loading.gif";
import { TraitShopTrait } from "./pages/trait_shop.tsx";

//shortening the wallet address
export const formatAddress = (address: string | any[]) => {
  if (!address) {
    return ""; // or some default value
  }

  const firstThree = address.slice(0, 3);
  const lastThree = address.slice(-3);
  return `${firstThree}...${lastThree}`;
};

//transaction of cubes

export async function cube_buy_request_txs(
  wallet: string,
  amount: number
): Promise<Transaction[]> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const user_cube_fee_tx = makePaymentTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.RECEIVER_WALLET,
      amount: algosToMicroalgos(
        amount * (constant.CUBE_BUY_FEE / constant.CUBE_BUY_AMOUNT)
      ),
      suggestedParams: params,
      note: enc.encode(`${amount} Cubes buy fee`),
    });

    const cube_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: constant.CREATOR_WALLET,
      to: wallet,
      amount: amount,
      suggestedParams: params,
      assetIndex: constant.CUBE_ASSET_ID,
    });

    cube_transfer_tx.fee = 0;
    user_cube_fee_tx.fee = 2 * 1000;

    const groupID = computeGroupID([user_cube_fee_tx, cube_transfer_tx]);
    user_cube_fee_tx.group = groupID;
    cube_transfer_tx.group = groupID;

    return [user_cube_fee_tx, cube_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}

//claiming of cubes

export async function token_claim_request_tx(
  wallet: string
): Promise<Transaction> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const cube_swap_request_tx =
      makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: wallet,
        amount: 0,
        suggestedParams: params,
        assetIndex: constant.CUBE_ASSET_ID,
        note: enc.encode("Weekly Force Cube claim"),
      });

    return cube_swap_request_tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function force_cube_optin(
  wallet: string,
  assetID: number
): Promise<Transaction> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const cube_optin_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: wallet,
      amount: 0,
      suggestedParams: params,
      assetIndex: assetID,
      note: enc.encode("Opt IN"),
    });

    return cube_optin_tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

//get Balance from account
export async function getBalance(wallet: string) {
  try {
    let response = await fetch(
      constant.NODE_ENDPOINT +
        "/v2/accounts/" +
        wallet 
    );
     
    let responseJson = await response.json();
    let balance = parseInt((responseJson.amount / 10 ** 6) as any);
    let asset = responseJson.assets.find(
      (asset: any) => asset["asset-id"] === constant.CUBE_ASSET_ID
    );
    if (asset) {
      return [balance, asset.amount];
    } else {
      return [balance, 0];
    }
  } catch (error) {
    return [0, 0];
  }
}

//check if wallet is opted into asset
export async function isWalletOptedIn(wallet: string, assetId: number): Promise<boolean> {
  try {
    // Make the API request for a specific asset
    const response = await axios.get(
      `${constant.NODE_ENDPOINT}/v2/accounts/${wallet}/assets/${assetId}`
    );
  
    // If the response is successful and asset-holding is found
    if (response.status === 200 && response.data["asset-holding"]) {
      return true; // User has opted in, regardless of the amount
    } else {
      return false; // Asset not found or response is not 200
    }
  } catch (error: any) {
    // Handle case where asset is not found
    if (error.response && error.response.data && error.response.data.message === "account asset info not found") {
      return false; // User has not opted in
    }
    // Log other errors and return false
    console.error(`Failed to check if wallet has asset: ${error}`);
    return false;
  }
}

export async function swap_request_tx(
  wallet: string,
  first_asset: number,
  second_asset: number,
  traits: string[]
): Promise<Transaction> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();
    const data = {
      fa: first_asset,
      sa: second_asset,
      t: traits,
    };

    if (data.fa === data.sa) throw new Error("You can't swap the same asset");

    const cube_swap_request_tx =
      makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: constant.CREATOR_WALLET,
        amount: 0,
        suggestedParams: params,
        assetIndex: constant.CUBE_ASSET_ID,
        note: enc.encode(JSON.stringify(data)),
      });

    return cube_swap_request_tx;
  } catch (error: any) {
    throw new Error(error);
  }
}
export async function getAssetsFromAddress(address: string): Promise<number[]> {
  // Initial threshold value for the number of assets to fetch in a single request
  let threshold = 1000;

  // Making an asynchronous HTTP GET request to fetch user assets using the provided wallet address
  let userAssets = await axios.get(
    `${constant.INDEXER_ENDPOINT}/accounts/${address}/assets`
  );

  // While the number of fetched assets reaches the threshold, continue fetching more assets
  while (userAssets.data.assets.length === threshold) {
    // Fetching the next set of assets using the "next-token" from the previous response
    const nextAssets = await axios.get(
      `${constant.INDEXER_ENDPOINT}/accounts/${address}/assets?next=${userAssets.data["next-token"]}`
    );

    // Concatenating the newly fetched assets to the existing list of assets
    userAssets.data.assets = userAssets.data.assets.concat(
      nextAssets.data.assets
    );

    // Updating the "next-token" for the subsequent requests
    userAssets.data["next-token"] = nextAssets.data["next-token"];

    // Increasing the threshold for the next iteration
    threshold += 1000;
  }

  // Filtering and mapping the fetched assets to include only those with positive amounts
  // and belonging to specific asset IDs specified in constant.ASSET_IDS
  return userAssets.data.assets
    .filter(
      (asset: any) =>
        asset.amount > 0 && constant.ASSET_IDS.includes(asset["asset-id"])
    )
    .map((asset: any) => asset["asset-id"]);
}


export const ipfsToData = async (asset_url: string, asset_reserve: string) => {
  let traits = "";
  let url = asset_url;

  if (!asset_url) return { url: "", traits: "" };

  try {
    if (asset_url.startsWith("template")) {
      const codec = asset_url.split(":")[3];

      try {
        url = await getMetadataFromReserveAddress(asset_reserve, codec);
        
      } catch (error) {
        console.error("Error getting metadata from reserve address:", error);
      }

      try {
        traits = await decodeMetadata(asset_reserve, codec);
        
      } catch (error) {
        console.error("Error decoding metadata:", error);
      }

      if (url.startsWith("ipfs://"))
        url = `${constant.IPFS_PREFIX}/${url.slice(7)}`;

      if (url === "") url = loadingGIF;

    } else if (asset_url.endsWith("#arc3")) {
      const urlBase = asset_url.slice(0, -5);

      try {
        if (urlBase.startsWith("ipfs://")) {
          const response = await axios.get(
            `${constant.IPFS_PREFIX}/${urlBase.slice(7)}`
          );
          url = response.data.image.startsWith("ipfs://")
            ? `${constant.IPFS_PREFIX}/${response.data.image.slice(7)}`
            : response.data.image;
          traits = response.data.traits || "";
        } else {
          const response = await axios.get(urlBase);
          url = response.data.image.startsWith("ipfs://")
            ? `${constant.IPFS_PREFIX}/${response.data.image.slice(7)}`
            : response.data.image;
          traits = response.data.traits || "";
        }
      } catch (error) {
        console.error("Error fetching data for #arc3 URL:", error);
      }

    } else if (asset_url.startsWith("https://gateway.pinata.cloud/ipfs/")) {
      url = `${constant.IPFS_PREFIX}/${asset_url.slice(33)}`;
    } else if (asset_url.startsWith("ipfs://")) {
      url = `${constant.IPFS_PREFIX}/${asset_url.slice(7)}`;
    }

    if (url.includes("#")) {
      url = url.split("#")[0];
    }

  } catch (error) {
    console.error("Error processing asset URL:", error);
  }

  
  return { url, traits };
};


export const ipfsToUrl = async (
  asset_url: string,
  asset_reserve: string
): Promise<string> => {
  if (!asset_url) return "";
  if (asset_url.startsWith("template")) {
    const codec = asset_url.split(":")[3];
    const url = await getMetadataFromReserveAddress(asset_reserve, codec);
    if (url.startsWith("ipfs://"))
      return `${constant.IPFS_PREFIX}/${url.slice(7)}`;
    if (url !== "") return url;
    return loadingGIF;
  }
  if (asset_url.endsWith("#arc3")) {
    const url = asset_url.slice(0, -5);
    if (url.startsWith("ipfs://")) {
      const response = await axios.get(
        `${constant.IPFS_PREFIX}/${url.slice(7)}`
      );
      if (response.data.image.startsWith("ipfs://"))
        return `${constant.IPFS_PREFIX}/${response.data.image.slice(7)}`;
      return response.data.image;
    } else {
      const response = await axios.get(url);
      if (response.data.image.startsWith("ipfs://"))
        return `${constant.IPFS_PREFIX}/${response.data.image.slice(7)}`;
      return response.data.image;
    }
  }
  if (asset_url.startsWith("https://gateway.pinata.cloud/ipfs/")) {
    return `${constant.IPFS_PREFIX}/${asset_url.slice(33)}`;
  }
  if (asset_url.startsWith("ipfs://"))
    return `${constant.IPFS_PREFIX}/${asset_url.slice(7)}`;
  return asset_url;
};

const getMetadataFromReserveAddress = async (
  reserveAddress: string,
  codec: string
): Promise<string> => {
  const decodedAddress = decodeAddress(reserveAddress);
  const ipfsCID = new cid(
    1,
    codec,
    multihash.encode(decodedAddress.publicKey, "sha2-256")
  ).toString();
  try {
    const response = await axios.get(`${constant.IPFS_PREFIX}/${ipfsCID}`);
    if (response.data.image) return response.data.image;
    return `${constant.IPFS_PREFIX}/${ipfsCID}`;
  } catch (error) {
    throw new Error("Invalid reserve address");
  }
};
// This function fetches user Comic book NFT information from blockchain
export async function getItemsToBurn(address: string): Promise<number[]> {
  let threshold = 1000;
  // Making an asynchronous HTTP GET request to fetch user assets using the provided wallet address
  let userAssets = await axios.get(
    `${constant.INDEXER_ENDPOINT}/accounts/${address}/assets`
  );

  // While the number of fetched assets reaches the threshold, continue fetching more assets
  while (userAssets.data.assets.length === threshold) {
    // Fetching the next set of assets using the "next-token" from the previous response
    const nextAssets = await axios.get(
      `${constant.INDEXER_ENDPOINT}/accounts/${address}/assets?next=${userAssets.data["next-token"]}`
    );

    // Concatenating the newly fetched assets to the existing list of assets
    userAssets.data.assets = userAssets.data.assets.concat(
      nextAssets.data.assets
    );

    // Updating the "next-token" for the subsequent requests
    userAssets.data["next-token"] = nextAssets.data["next-token"];

    // Increasing the threshold for the next iteration
    threshold += 1000;
  }

  // Filtering and mapping to include only assets with positive amounts and in specified groups
  return userAssets.data.assets
    .filter(
      (asset: any) =>
        asset.amount > 0 &&
        (constant.NFTGROUP1.includes(asset["asset-id"]) ||
          constant.NFTGROUP2.includes(asset["asset-id"]) ||
          constant.NFTGROUP3.includes(asset["asset-id"]))
    )
    .map((asset: any) => asset["asset-id"]);
}


//Trait map from arc19 - or ARC69

export const ipfsToTraits = async (
  asset_url: string,
  asset_reserve: string
): Promise<string> => {
  if (!asset_url) return "";
  if (asset_url.startsWith("template")) {
    const codec = asset_url.split(":")[3];
    const traits = await decodeMetadata(asset_reserve, codec);
    return traits;
  }
  return asset_url;
};

const decodeMetadata = async (
  reserveAddress: string,
  codec: string
): Promise<string> => {
  const decodedAddress = decodeAddress(reserveAddress);
  const ipfsCID = new cid(
    1,
    codec,
    multihash.encode(decodedAddress.publicKey, "sha2-256")
  ).toString();
  try {
    const response = await axios.get(`${constant.IPFS_PREFIX}/${ipfsCID}`);
    if (response.data.properties.traits) return response.data.properties.traits;
    if (response.data.properties) return response.data.properties;
    return `${constant.IPFS_PREFIX}/${ipfsCID}`;
  } catch (error) {
    throw new Error("Invalid reserve address");
  }
};

export async function trait_shop_check_tx(
  wallet: string,
  selected_asset: number,
  basic_trait: TraitShopTrait,
  special_trait: TraitShopTrait
): Promise<Transaction> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();
    const data = {
      bt: {
        id: basic_trait.id,
        name: basic_trait.trait_name,
        type: basic_trait.trait_type,
      },
      st: {
        id: special_trait.id,
        name: special_trait.trait_name,
        type: special_trait.trait_type,
      },
    };
    const trait_shop_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: 0,
      suggestedParams: params,
      assetIndex: selected_asset,
      note: enc.encode(JSON.stringify(data)),
    });

    return trait_shop_tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function mental_af_check_tx(
  wallet: string,
  asset_id: number
): Promise<Transaction> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const trait_shop_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: 0,
      suggestedParams: params,
      assetIndex: asset_id,
    });

    return trait_shop_tx;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function getAssetHolder(asset_id: number) {
  try {
    
    const response = await axios.get(
      `${constant.INDEXER_ENDPOINT}/assets/${asset_id}/balances`
        
    );
    const data = await response.data;

    const holder = data.balances.find((balance: any) => balance.amount === 1);

    
    return holder ? holder.address : "";
  } catch (error) {
    return "";
  }
}

export const fetchImageData = async (assetId: any) => {
  try {
    const response = await axios.get(
      `${constant.NODE_ENDPOINT}/v2/assets/${assetId}`
    );
    const url = await ipfsToUrl(
      response.data.params.url,
      response.data.params.reserve
    );
    const name = response.data.params.name;
    return { url, name, assetId };
  } catch (error) {
    console.error("Error fetching image URL:", error);

    return null;
  }
};

export const fetchAssetData = async (assetId: any) => {
  try {
    const response = await axios.get(
      `${constant.NODE_ENDPOINT}/v2/assets/${assetId}`
    );
    const { url, traits } = await ipfsToData(
      response.data.params.url,
      response.data.params.reserve
    );
    const name = response.data.params.name;
    return { url, traits, name, assetId };
  } catch (error) {
    console.error("Error fetching image URL:", error);

    return null;
  }
};

//NFT BURN TRX IS NOT FINISHED - WORK IN PROGRESS
export async function burn_nft_request_txs(
  wallet: string,
  groupAmount: number,
  asset_id: number
): Promise<Transaction[]> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const user_nft_send_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.RECEIVER_WALLET,
      amount: 1,
      suggestedParams: params,
      assetIndex: asset_id,
    });

    const cube_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: constant.CREATOR_WALLET,
      to: wallet,
      amount: groupAmount,
      suggestedParams: params,
      assetIndex: constant.CUBE_ASSET_ID,
      note: enc.encode(`${asset_id} juiced for ${groupAmount} Cubes`),
    });

    user_nft_send_tx.fee = 2 * 1000;
    cube_transfer_tx.fee = 0;

    const groupID = computeGroupID([user_nft_send_tx, cube_transfer_tx]);
    user_nft_send_tx.group = groupID;
    cube_transfer_tx.group = groupID;

    return [user_nft_send_tx, cube_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}

export const getMintStatus = async (wallet: any) => {
  try {
    const response = await axios.get(
      `${constant.NODE_ENDPOINT}/v2/accounts/${wallet}`
    );
    const data = response.data;
   

    const assetsWithAmountOne = data.assets.filter(
      (asset: { amount: number }) => asset.amount === 1
    );
    return assetsWithAmountOne.length;
  } catch (error) {
    console.error("Failed to get wallet assets:", error);
    return 0;
  }
};

export async function comic_buy_request_txs(
  wallet: string,
  amount: number,
  comicID: number,
  mintwallet: string
): Promise<Transaction[]> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const user_comic_fee_tx = makePaymentTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.RECEIVER_WALLET,
      amount: algosToMicroalgos(constant.COMIC_BUY_FEE),
      suggestedParams: params,
      note: enc.encode(`${comicID} Comic buy `),
    });
    const optInTx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: wallet,
      amount: 0,
      suggestedParams: params,
      assetIndex: comicID,
    });
    optInTx.fee = 0;

    const comic_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject(
      {
        from: mintwallet,
        to: wallet,
        amount: 1,
        suggestedParams: params,
        assetIndex: comicID,
      }
    );

    comic_transfer_tx.fee = 0;
    user_comic_fee_tx.fee = 3 * 1000;

    const groupID = computeGroupID([
      user_comic_fee_tx,
      optInTx,
      comic_transfer_tx,
    ]);
    user_comic_fee_tx.group = groupID;
    optInTx.group = groupID;
    comic_transfer_tx.group = groupID;

    return [user_comic_fee_tx, optInTx, comic_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}

export const getComicID = async (wallet: any) => {
  try {
    // Fetch the assets data for the given wallet
    const response = await axios.get(
      `${constant.NODE_ENDPOINT}/v2/accounts/${wallet}`
    );
    const data = response.data;

    // Filter assets to get only those with an amount of 1
    const assetsWithAmountOne = data.assets.filter(
      (asset: { amount: number }) => asset.amount === 1
    );

    // Check if there are any assets with amount 1
    if (assetsWithAmountOne.length === 0) {
      console.log("No more assets in the mint.");
      return null;
    }

    // Pick a random asset from the filtered list
    const randomIndex = Math.floor(Math.random() * assetsWithAmountOne.length);
    const randomAsset = assetsWithAmountOne[randomIndex];

    // Return the asset_id of the randomly selected asset
    return randomAsset["asset-id"];
  } catch (error) {
    console.error("Failed to get wallet assets:", error);
    return null;
  }
};


export async function comic_open_request_txs(
  wallet: string,
  comicID: number
): Promise<Transaction> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const trait_shop_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: 0,
      suggestedParams: params,
      assetIndex: comicID,
      note: enc.encode(`Open comic ${comicID}`),
    });

    return trait_shop_tx;
  } catch (error: any) {
    throw new Error(error);
  }
}


// This function fetches user assets based on the provided wallet address.
export async function getUserComics(address: string): Promise<number[]> {
  // Initial threshold value for the number of assets to fetch in a single request
  let threshold = 1000;

  // Making an asynchronous HTTP GET request to fetch user assets using the provided wallet address
  let userAssets = await axios.get(
    `${constant.INDEXER_ENDPOINT}/accounts/${address}/assets`
  );

  // While the number of fetched assets reaches the threshold, continue fetching more assets
  while (userAssets.data.assets.length === threshold) {
    // Fetching the next set of assets using the "next-token" from the previous response
    const nextAssets = await axios.get(
      `${constant.INDEXER_ENDPOINT}/accounts/${address}/assets?next=${userAssets.data["next-token"]}`
    );

    // Concatenating the newly fetched assets to the existing list of assets
    userAssets.data.assets = userAssets.data.assets.concat(
      nextAssets.data.assets
    );

    // Updating the "next-token" for the subsequent requests
    userAssets.data["next-token"] = nextAssets.data["next-token"];

    // Increasing the threshold for the next iteration
    threshold += 1000;
  }

  // Filtering and mapping the fetched assets to include only those with positive amounts
  // and belonging to specific asset IDs specified in constant.ASSET_IDS
  return userAssets.data.assets
    .filter(
      (asset: any) =>
        asset.amount > 0 && constant.COMIC_ASSET_ID.includes(asset["asset-id"])
    )
    .map((asset: any) => asset["asset-id"]);
}


export async function send_gift_txs(
  wallet: string,
  comicID: number
): Promise<Transaction[]> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const feeTsx = makePaymentTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: algosToMicroalgos(0.11),
      suggestedParams: params,
      note: enc.encode(`${comicID} Sent as a gift `),
    });

    const gift_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: 1,
      suggestedParams: params,
      assetIndex: comicID,
    });

    feeTsx.fee = 1 * 1000;
    gift_transfer_tx.fee = 1 * 1000;

    const groupID = computeGroupID([feeTsx, gift_transfer_tx]);

    feeTsx.group = groupID;
    gift_transfer_tx.group = groupID;

    return [feeTsx, gift_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function test_QR_transaction(
  wallet: string
): Promise<Transaction[]> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const feeTsx = makePaymentTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: algosToMicroalgos(0.11),
      suggestedParams: params,
      note: enc.encode(`1047740777 Sent as a gift `),
    });

    const gift_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: 1,
      suggestedParams: params,
      assetIndex: 1047740777,
    });

    feeTsx.fee = 1 * 1000;
    gift_transfer_tx.fee = 1 * 1000;

    const groupID = computeGroupID([feeTsx, gift_transfer_tx]);

    feeTsx.group = groupID;
    gift_transfer_tx.group = groupID;

    return [feeTsx, gift_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}


export async function claim_trinket_txs(
  wallet: string,
  trinket: any
): Promise<Transaction[]> {
  try {
    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    const {
      name: trinket_name,
      reward_id,
      trinket_id,
      requirement: { asset_id_required, quantity: requirement_quantity, requirement_type },
      reward_quantity,
    } = trinket;

    const data = {
      trinket: {
        id: trinket_id,
        name: trinket_name,
        type: requirement_type,
      },
         };

         let trinket_claim_tx: Transaction;
         let opt_in_tx: Transaction | undefined; 
         let transactions: Transaction[] = [];
    
    const isOptedIn = await isWalletOptedIn(wallet, reward_id);

    // Create trinket claim transaction based on the requirement_type
    if (requirement_type === "Transaction") {
      // If trinket_requirement_type is "Transaction", create a special transaction
      trinket_claim_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: constant.CREATOR_WALLET,
        amount: 0,
        suggestedParams: params,
        assetIndex: constant.CUBE_ASSET_ID,
        note: enc.encode(JSON.stringify(data)),
      });
    } else {
      // Otherwise, create the default transaction
      trinket_claim_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: constant.CREATOR_WALLET,
        amount: requirement_quantity, // Use requirement_quantity
        suggestedParams: params,
        assetIndex: asset_id_required, // Use asset_id_required
        note: enc.encode(JSON.stringify(data)),
      });
    }

    // If the user is not opted into the reward asset, create an opt-in transaction
    if (!isOptedIn) {
      opt_in_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: wallet,
        amount: 0,
        suggestedParams: params,
        assetIndex: reward_id,
      });
    }

    const trinket_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: constant.CREATOR_WALLET,
      to: wallet,
      amount: reward_quantity,
      suggestedParams: params,
      assetIndex: reward_id,
    });

    trinket_claim_tx.fee = 2 * 1000;
    trinket_transfer_tx.fee = 0
    if (opt_in_tx) {
      opt_in_tx.fee = 0; // Opt-in fee is 0
      trinket_claim_tx.fee = 3 * 1000; // Increase fee if opt-in transaction is present
      transactions.push(trinket_claim_tx, opt_in_tx, trinket_transfer_tx); // Push all transactions
    } else {
      transactions.push(trinket_claim_tx, trinket_transfer_tx); // No opt-in transaction, push claim and transfer only
    }

    const groupID = computeGroupID(transactions);

    transactions.forEach(tx => (tx.group = groupID));

    return transactions;
  } catch (error: any) {
    throw new Error(error);
  }
}




export async function send_asset_with_optin(
  wallet: string,
  comic_trinket_id: number,
  amount:number,
  ): Promise<Transaction[]> {
  try {
   

    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

         
    const opt_in_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: wallet,
        amount: 0,
        suggestedParams: params,
        assetIndex: comic_trinket_id,
      });
    
    const asset_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: constant.CREATOR_WALLET,
      to: wallet,
      amount: amount,
      suggestedParams: params,
      assetIndex: comic_trinket_id,
    });
    
    
    opt_in_tx.fee = 2 * 1000;
    asset_transfer_tx.fee=0
    
    const groupID = computeGroupID([opt_in_tx, asset_transfer_tx]);
    opt_in_tx.group = groupID;
    asset_transfer_tx.group = groupID;
    
    return [opt_in_tx, asset_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}

export const downloadAsset = async (downloadUrl, assetName) => {
  if (!downloadUrl || !assetName) return;

  try {
    const response = await fetch(downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `${assetName}.png`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading asset:", error);
  }
};


//GET NFD



export const getNFD = async (address) => {
  
  try {
    const response = await fetch(`https://api.nf.domains/nfd/lookup?address=${address}`, {
      method: 'GET',
      headers: {},
    });
    const data = await response.json();
    const [firstKey] = Object.keys(data);
    const name = data[firstKey]?.name;
    
    return name;

  } catch (error) {
    console.error("Error downloading asset:", error);
  }
};



export const getAlgoPrice = async (): Promise<number> => {
  try {
    const response = await axios.get("https://free-api.vestige.fi/currency/prices");
    const usdPrice = response.data["USD"]; // Extracting the USD price
    if (!usdPrice) {
      throw new Error("USD price not found in API response.");
    }
    return usdPrice;
  } catch (error) {
    console.error("Error fetching ALGO price:", error);
    throw error;
  }
};




export async function raffle_entry_tx(
  wallet: string,
  amount:number,
  asset_id: number,
  type:string

  ): Promise<Transaction[]> {
  try {
   

    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

    let asset_transfer_tx: Transaction | undefined;
    let algo_tx: Transaction | undefined; 
    let transactions: Transaction[] = []; 
    
    const opt_in_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: wallet,
        amount: 0,
        suggestedParams: params,
        assetIndex: asset_id,
      });

    if (type === "cubes"){
      const noteData = "Cube raffle tickets"
      asset_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: amount,
      suggestedParams: params,
      assetIndex: constant.CUBE_ASSET_ID,
      note: enc.encode(JSON.stringify(noteData)),
      
    })}
    if (type === "free") {
      const noteData =  "Free Raffle Ticket"
      asset_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: constant.CREATOR_WALLET,
        amount: amount,
        suggestedParams: params,
        assetIndex: constant.CUBE_ASSET_ID,
        note: enc.encode(JSON.stringify(noteData)),
      });
    }
    if(type === "algos")
    {
      const noteData = "Algo raffle tickets"
      algo_tx = makePaymentTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: constant.CREATOR_WALLET,
      amount: algosToMicroalgos(amount),
      suggestedParams: params,
      note: enc.encode(JSON.stringify(noteData)),
    })
  }
    opt_in_tx.fee = 2 * 1000;
    
    if (asset_transfer_tx){asset_transfer_tx.fee=0
      transactions.push(opt_in_tx, asset_transfer_tx);
    } 
    if (algo_tx) {algo_tx.fee=0
      transactions.push(opt_in_tx, algo_tx)}
    
       
    const groupID = computeGroupID(transactions);
    transactions.forEach(tx => (tx.group = groupID));
    
    return transactions;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function merge_comic_tx(
  wallet: string,
  comic_merge_id: number,
  clicked_trait:number,
  ): Promise<Transaction[]> {
  try {
   

    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder();

         
    const opt_in_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
        from: wallet,
        to: wallet,
        amount: 0,
        suggestedParams: params,
        assetIndex: clicked_trait,
      });
    
    const asset_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: constant.CREATOR_WALLET,
      to: wallet,
      amount: 1,
      suggestedParams: params,
      assetIndex: clicked_trait,
    });
    const comic_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: constant.MINT_WALLET,
      to: wallet,
      amount: 1,
      suggestedParams: params,
      assetIndex: comic_merge_id,
    });
    
    opt_in_tx.fee = 3 * 1000;
    asset_transfer_tx.fee=0
    comic_transfer_tx.fee=0

    const groupID = computeGroupID([opt_in_tx, asset_transfer_tx,comic_transfer_tx]);
    opt_in_tx.group = groupID;
    asset_transfer_tx.group = groupID;
    comic_transfer_tx.group = groupID;

    return [opt_in_tx, asset_transfer_tx, comic_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}


export async function simple_asset_transfer(
  wallet: string,
  id: number,
  amount:number,
  ): Promise<Transaction[]> {
  try {
   

    const algodClient = new Algodv2("", constant.NODE_ENDPOINT, "");
    const params = await algodClient.getTransactionParams().do();
    

    
    const asset_transfer_tx = makeAssetTransferTxnWithSuggestedParamsFromObject({
      from: wallet,
      to: wallet,
      amount: amount,
      suggestedParams: params,
      assetIndex: id,
      
    });
           
    asset_transfer_tx.fee = 1 * 1000;
        
    return [asset_transfer_tx];
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function check_cookie(walletAddress: string) {
const response = await axios.get(`${constant.API_ENDPOINT}/validate-no-sign-tx/`, {
  params: { walletAddress },  // Send wallet address as parameter
  withCredentials: true      // This ensures the cookie is sent
});
return response.data;
}

export async function delete_cookie(walletAddress: string) {
  const response = await axios.get(`${constant.API_ENDPOINT}/delete-no-sign-tx/`, {
    params: { walletAddress },  // Send wallet address as parameter
    withCredentials: true      // This ensures the cookie is sent
  });
  return response.data;
  }
  