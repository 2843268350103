import { BrowserRouter, useNavigate } from "react-router-dom";
import mmlogo from "../../assets/mm2.png";
import city from "../../assets/city.jpg";
import shop from "../../assets/shop.jpg";
import "./welcomescreen.css";

const WelcomeScreen = ({ setSiteType }) => {
  const navigate = useNavigate();

  const chooseSite = (type) => {
    localStorage.setItem("siteType", type);
    setSiteType(type);
    navigate(`/${type}`);
  };

  return (
    <div className="welcome-site">
      <div className="welcome-logo">
        <h2>WELCOME TO</h2>
        <img src={mmlogo} alt="mmlogo" />
        <h3>IT LOOKS LIKE YOU ARE VISING OUR SITE FOR THE FIRST TIME!</h3>
        <h3>HOW DO YOU WANT TO EXPERIENCE THE SITE?</h3>
        <h6>
          This will set your preferred experience. Don’t worry, you can always
          change it later.{" "}
        </h6>
      </div>

      <div className="exp-cards-wrapper">
        <div className="experience-card" onClick={() => chooseSite("basic")}>
          <div
            className="exp-card-background"
            style={{
              backgroundImage: `url(${shop})`,
            }}
          ></div>
          <div className="exp-card-text">
            <h5>
              Ready for a Quick and Simple dive into Mental Marvin web comics?
            </h5>
            <h3>COMIC READER</h3>

            <p></p>
            <h6>
              If you're looking for a simple and lite experience, this is the
              perfect place for you! Read free Mental Marvin comics, enjoy our
              daily strips, and explore key community links—all in one place.
              Whether you're here for a quick read or just want to stay
              connected, we've got you covered!
            </h6>
          </div>
          <ul className="exp-card-list">
            <li>News</li>
            <li>Comics</li>
            <li>Daily Strips</li>
            <li>Links</li>
          </ul>
        </div>
        <div className="experience-card" onClick={() => chooseSite("advanced")}>
          <div
            className="exp-card-background"
            style={{
              backgroundImage: `url(${city})`,
            }}
          ></div>
          <div className="exp-card-text">
            <h5>
              Ready to dive headfirst into the full Mental Marvin universe?
            </h5>
            <h3>GO FULL MENTAL!</h3>
            <p></p>
            <h6>
              Embark on an epic journey through Mental Marvin universe, get
              Force Cubes, gather JUNK, buy and swap exclusive traits, read and
              buy comics early. Order merch and physical comics. Get ready to
              explore the wild, untamed world of Mental Marvin—where the only
              rule is to go *FULL MENTAL*!
            </h6>
          </div>
          <ul className="exp-card-list">
            <li>Comic Books</li>
            <li>NFT Collections</li>
            <li>Force Cubes</li>
            <li>Find JUNK</li>
            <li>Trait Shop / Swap</li>
            <li>Missions</li>
            <li>Raffles</li>
            <li>Merch and Prints</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
