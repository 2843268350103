import React, { useState, useEffect, useRef } from "react";
import { useWalletInfo } from "../utils/UserWalletHook.js";
import { useWallet } from "@txnlab/use-wallet";
import axios from "axios";
import { toast } from "react-toastify";
import { encode } from "uint8-to-base64";
import { API_ENDPOINT, API_ENDPOINT2 } from "../../constants.ts";
import "./raffles.css";
import AssetBoxForSwap from "../assetboxoptimal.tsx";
import cube_icon from "../../assets/cube.webp";
import algo_logo from "../../assets/algo.webp";
import CountdownTimer from "../utils/CountdownTimer.js";
import { formatAddress, raffle_entry_tx, getBalance } from "../utilities.ts";
import algosdk from "algosdk";
import { FaLink } from "react-icons/fa6";
import { FaRegPlusSquare } from "react-icons/fa";
import ConnectDropdown from "../ConnectDropdown";
import MakeRaffleModal from "./MakeRaffleModal.js";
import FreeRaffleModal from "./FreeRaffleModal.js";
import success from "../../assets/sounds/success.mp3";

const Raffles = () => {
  const [loading, setLoading] = useState(false);
  const { walletAddress } = useWalletInfo();
  const { signTransactions } = useWallet();
  const [rewardNames, setRewardNames] = useState({});
  const [raffleEntries, setRaffleEntries] = useState({});
  const [entries, setEntries] = useState(1);
  const [raffles, setRaffles] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [filter, setFilter] = useState("endTimeLowest");
  const [showPopover, setShowPopover] = useState(false);
  const [userBalance, setUserBalance] = useState([0, 0]);
  const dropdownRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [selectedRaffle, setSelectedRaffle] = useState({
    raffleId: "",
    raffleName: "",
    asset_id: "",
  });

  const playSound = () => {
    const audio = new Audio(success);
    audio.play();
  };

  const fetchRaffleData = async () => {
    setLoading(true);
    try {
      // get data for raffles

      const raffleResponse = await axios.get(`${API_ENDPOINT}/check-raffles/`, {
        params: { wallet_address: walletAddress },
      });
      const raffleData = raffleResponse.data;

      // Set the fetched data
      setRaffles(raffleData);
    } catch (error) {
      console.error("Error fetching missions", error);
    } finally {
      setLoading(false);
    }
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal2 = (raffleId, raffleName, asset_id) => {
    setSelectedRaffle({ raffleId, raffleName, asset_id });
    setIsModal2Open(true);
  };

  // Function to close the modal
  const closeModal2 = () => {
    setIsModal2Open(false);
  };

  const togglePopover = () => {
    setShowPopover(!showPopover);

    // Hide the popover after a few seconds
    setTimeout(() => {
      setShowPopover(false);
    }, 3000); // 3 seconds
  };

  const handleNameReceived = (raffleId, name) => {
    setRewardNames((prevNames) => ({
      ...prevNames,
      [raffleId]: name,
    }));
  };

  useEffect(() => {
    if (walletAddress) {
      fetchRaffleData();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress) {
      fetchBalance();
    }
  }, [walletAddress]);

  const fetchBalance = async () => {
    try {
      const balance = await getBalance(walletAddress);

      setUserBalance(balance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Sort raffles based on the selected filter
  const getFilteredRaffles = () => {
    return [...raffles]
      .filter((raffle) => raffle.active) // Only include active raffles
      .sort((a, b) => {
        switch (filter) {
          case "endTimeLowest":
            return new Date(a.end_time) - new Date(b.end_time);
          case "endTimeHighest":
            return new Date(b.end_time) - new Date(a.end_time);
          case "entriesLowest":
            return a.total_entries - b.total_entries;
          case "entriesHighest":
            return b.total_entries - a.total_entries;
          default:
            return 0; // No sorting
        }
      });
  };

  const filteredRaffles = getFilteredRaffles();

  // Function to handle + button click
  const incrementEntries = (raffleId) => {
    setRaffleEntries((prevEntries) => ({
      ...prevEntries,
      [raffleId]: (prevEntries[raffleId] || 0) + 1, // Increment only for the specific raffle
    }));
  };

  // Function to handle - button click
  const decrementEntries = (raffleId) => {
    setRaffleEntries((prevEntries) => ({
      ...prevEntries,
      [raffleId]: Math.max((prevEntries[raffleId] || 0) - 1, 0), // Prevent negative values
    }));
  };

  // Function to handle manual input
  const handleInputChange = (raffleId, value) => {
    if (!isNaN(value) && value >= 0) {
      setRaffleEntries((prevEntries) => ({
        ...prevEntries,
        [raffleId]: Number(value),
      }));
    }
  };

  async function sendRaffleEntry(raffleId, entries, asset_id, type) {
    if (!walletAddress) {
      toast.info("Please connect your wallet first!");
      return;
    }

    // Check if entries is a whole number
    if (!Number.isInteger(entries) || entries <= 0) {
      toast.info("The number of entries must be a whole number");
      return;
    }
    setIsClicked(true);
    toast.info("Sign the transaction to open!");

    try {
      const transaction = await raffle_entry_tx(
        walletAddress,
        entries,
        asset_id,
        type
      );
      if (!transaction) {
        toast.info("Something went wrong while creating the transactions!");
        setIsClicked(false);
        return;
      }
      let opt_in_tx;
      let raffle_entry_trx;

      const signedTransaction = await signTransactions([
        algosdk.encodeUnsignedTransaction(transaction[0]),
        algosdk.encodeUnsignedTransaction(transaction[1]),
      ]);
      opt_in_tx = encode(signedTransaction[0]);
      raffle_entry_trx = encode(signedTransaction[1]);

      toast.info("Sending to the raffle!");
      const res = await axios.post(`${API_ENDPOINT}/check-raffles/`, {
        opt_in_tx,
        raffle_entry_trx,
        raffleId,
      });

      if (res.status === 200) {
        toast.success("Successfully entered the raffle!");
        fetchRaffleData();
        playSound();
        setIsClicked(false);
      } else {
        toast.error("Something went wrong while raffling!");
        setIsClicked(false);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data);
      } else {
        toast.error("Something went wrong!");
      }
      setIsClicked(false);
    }
  }

  return (
    <>
      <div className="raffle-container">
        <MakeRaffleModal
          isOpen={isModalOpen}
          onClose={closeModal}
          wallet={walletAddress}
          fetchRaffleData={fetchRaffleData}
        />
        <FreeRaffleModal
          isOpen={isModal2Open}
          onClose={closeModal2}
          wallet={walletAddress}
          raffle={selectedRaffle}
          fetchRaffleData={fetchRaffleData}
        />
        <div className="rafflemain">
          <div className="user-cube-balance">
            <img src={cube_icon} alt="cube" />
            <h6>{userBalance[1]}</h6>

            <img src={algo_logo} alt="cube" />
            <h6>{userBalance[0]}</h6>
          </div>
          {/* Dropdown for filtering */}
          <select
            value={filter}
            onChange={handleFilterChange}
            className="raffle-form-select"
          >
            <option value="endTimeLowest">End Time: Lowest</option>
            <option value="endTimeHighest">End Time: Highest</option>
            <option value="entriesLowest">Entries: Lowest</option>
            <option value="entriesHighest">Entries: Highest</option>
          </select>
          <FaRegPlusSquare className="user-add-icon" onClick={openModal} />
          {showPopover && <div className="raffle-popover">SOON!!</div>}
          <div className="raffle-box-container">
            {raffles.length !== 0 ? (
              <>
                {filteredRaffles.map((raffle, index) => (
                  <div key={raffle.raffle_id} className="raffle-box">
                    <h4>{raffle.raffle_name}</h4>
                    <AssetBoxForSwap
                      asset_id={raffle.asset_id}
                      onNameReceived={(name) =>
                        handleNameReceived(raffle.raffle_id, name)
                      }
                    />
                    <div className="raffle-description">
                      {raffle.description}
                    </div>

                    <div className="raffle-reward">
                      Reward: {rewardNames[raffle.raffle_id]}{" "}
                      <a
                        href={`https://allo.info/asset/${raffle.asset_id}`}
                        target="_blank"
                        rel="noreferrer"
                        className=""
                      >
                        <FaLink />
                      </a>
                    </div>

                    <div className="raffle-timer-container">
                      {raffle.winner !== null ? (
                        <>
                          <span>WINNER: </span>
                          {formatAddress(raffle.winner)}
                        </>
                      ) : (
                        <>
                          Expires in :{" "}
                          <CountdownTimer
                            huntEndTimestamp={new Date(
                              raffle.end_time
                            ).getTime()}
                          />
                        </>
                      )}
                    </div>
                    <div className="raffle-type">
                      Raffle Type:{" "}
                      <span>
                        {raffle.type === "cubes" ? (
                          <img src={cube_icon} alt="cube" />
                        ) : (
                          <img src={algo_logo} alt="algo" />
                        )}
                      </span>
                    </div>
                    <div className="raffle-entries">
                      <div>Total Entries : {raffle.total_entries || 0} </div>
                      <div>Your Entries: {raffle.user_entries || 0} </div>
                    </div>
                    <div className="raffle-entries-button">
                      <div className="raffle-entries-pick">
                        <button
                          className="btn btn-secondary"
                          onClick={() => decrementEntries(raffle.raffle_id)}
                        >
                          -
                        </button>
                        <input
                          type="number"
                          step="1"
                          min="1"
                          pattern="\d*"
                          value={raffleEntries[raffle.raffle_id] || 0}
                          onChange={(e) => {
                            const value = parseInt(e.target.value, 10) || 0;
                            handleInputChange(raffle.raffle_id, value);
                          }}
                          className="entries-input"
                        />

                        <button
                          className="btn btn-secondary"
                          onClick={() => incrementEntries(raffle.raffle_id)}
                        >
                          +
                        </button>
                      </div>
                      <div>
                        {raffle.winner !== null ||
                        new Date(raffle.end_time).getTime() <= Date.now() ||
                        raffle.creator === walletAddress ? (
                          <button className="btn btn-secondary" disabled>
                            {raffle.creator === walletAddress
                              ? "CREATOR"
                              : "CLOSED"}
                          </button>
                        ) : (
                          <>
                            {walletAddress ? (
                              <>
                                {isClicked ? (
                                  <button className="btn btn-warning" disabled>
                                    <div
                                      className="spinner-border text-light mr-2"
                                      role="status"
                                    ></div>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-info"
                                    onClick={() =>
                                      sendRaffleEntry(
                                        raffle.raffle_id,
                                        raffleEntries[raffle.raffle_id] || 0,
                                        raffle.asset_id,
                                        raffle.type
                                      )
                                    }
                                  >
                                    ENTER
                                  </button>
                                )}
                              </>
                            ) : (
                              <div className="not-connected-button">
                                <ConnectDropdown
                                  isOpen={dropdownOpen}
                                  onClose={() => setDropdownOpen(false)}
                                  ref={dropdownRef}
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="free-raffle">
                      {raffle.type === "algos" ? (
                        <button
                          type="button"
                          className={`btn ${
                            raffle.free_ticket ||
                            new Date(raffle.end_time).getTime() <= Date.now()
                              ? "btn-secondary"
                              : "btn-info"
                          }`}
                          disabled={
                            raffle.free_ticket ||
                            new Date(raffle.end_time).getTime() <= Date.now() ||
                            raffle.creator === walletAddress
                          }
                          onClick={() =>
                            openModal2(
                              raffle.raffle_id,
                              raffle.raffle_name,
                              raffle.asset_id
                            )
                          }
                        >
                          {raffle.free_ticket ? (
                            <>FREE TICKET CLAIMED</>
                          ) : (
                            <> 🎉 FREE TICKETS 🎉</>
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          disabled
                        >
                          NO FREE TICKETS
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="raffle-not-connected">
                <h6>
                  Our AF are on the hunt for some sweet JUNK for the raffles.
                  <br />
                  To see all our Raffles please connect your wallet!
                </h6>

                <div className="not-connected-button">
                  <ConnectDropdown
                    isOpen={dropdownOpen}
                    onClose={() => setDropdownOpen(false)}
                    ref={dropdownRef}
                  />
                </div>
              </div>
            )}
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default Raffles;
