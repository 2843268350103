import React, { useState, useEffect, useRef } from "react";
import "./strips.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Strips = () => {
  const [loading, setLoading] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => {
    setSelectedImage(null);
  };
  useEffect(() => {
    const importImages = () => {
      const context = require.context("../../assets/strips", false, /\.jpg$/);
      const imageData = context.keys().map((imagePath) => {
        return {
          src: context(imagePath),
          name: imagePath.replace("./", "").replace(".jpg", ""), // Extract filename
        };
      });
      setImages(imageData);
      setLoading(false);
    };

    importImages();
  }, []);

  return (
    <>
      <div className="strip-container">
        <div className="stripmain">
          <div className="strip-box">
            {loading
              ? // Skeleton loader while images are loading
                Array.from({ length: 10 }).map((_, index) => (
                  <div key={index} className="strip-thumbs">
                    <Skeleton
                      baseColor="#202020"
                      highlightColor="#9df6ff33"
                      className="auto-skeleton"
                    />
                    <Skeleton
                      baseColor="#202020"
                      highlightColor="#9df6ff33"
                      height={50}
                    />
                  </div>
                ))
              : images.map((strip, index) => (
                  <div
                    key={index}
                    className="strip-thumbs"
                    onClick={() => setSelectedImage(strip.src)}
                  >
                    <LazyLoadImage src={strip.src} alt="cube" effect="blur" />
                    <h5>{strip.name}</h5>
                  </div>
                ))}
          </div>

          {selectedImage && (
            <div className="popup-overlay" onClick={handleClose}>
              <div className="popup-content">
                <img src={selectedImage} alt="Enlarged" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Strips;
